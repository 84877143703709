import React from 'react';
import { BackButton } from '../../components/common/BackButton';

export function TermsAndConditions() {
  return (
    <div className="min-h-screen bg-white dark:bg-gray-900">
      <div className="max-w-4xl mx-auto px-4 py-12">
        <BackButton />
        
        <div className="mt-8 prose dark:prose-invert max-w-none">
          <h1 className="text-3xl font-bold mb-6">Terms and Conditions</h1>
          <p className="text-sm text-gray-500 mb-8">Last Updated: 12/12/2024</p>

          <div className="space-y-8">
            <section>
              <p className="mb-6">
                Welcome to StardomApp! By accessing or using our platform, you agree to comply with and be bound by the following Terms and Conditions. Please read them carefully. If you do not agree, please refrain from using StardomApp.
              </p>
            </section>

            <section>
              <h2 className="text-xl font-semibold mb-4">1. Overview of StardomApp</h2>
              <p>
                StardomApp is an all-in-one platform for public figures to sell products, services, NFTs, live experiences, and more. For fans, it serves as a single destination to purchase and interact with their favorite celebrities.
              </p>
            </section>

            <section>
              <h2 className="text-xl font-semibold mb-4">2. Definitions</h2>
              <ul className="list-disc pl-6 space-y-2">
                <li>"Platform": Refers to StardomApp (web, app, and related services).</li>
                <li>"Users": Individuals or fans accessing and using the platform.</li>
                <li>"Sellers": Celebrities, public figures, or authorized individuals listing products or services.</li>
                <li>"Content": Any text, images, videos, NFTs, live streams, or digital goods uploaded to StardomApp.</li>
              </ul>
            </section>

            <section>
              <h2 className="text-xl font-semibold mb-4">3. User Eligibility</h2>
              <ul className="list-disc pl-6 space-y-2">
                <li>You must be at least 13 years old to use StardomApp.</li>
                <li>Users engaging in purchases or financial transactions must be 18 years or older or have parental/guardian consent.</li>
              </ul>
            </section>

            <section>
              <h2 className="text-xl font-semibold mb-4">4. User Accounts</h2>
              <ul className="list-disc pl-6 space-y-2">
                <li>Registration: Users must create an account and provide accurate information.</li>
                <li>Account Responsibility: Users are responsible for maintaining the confidentiality of their account credentials.</li>
                <li>Prohibited Activity: Account sharing, impersonation, or fraudulent activity will result in suspension or termination.</li>
              </ul>
            </section>

            <section>
              <h2 className="text-xl font-semibold mb-4">5. Sellers on StardomApp</h2>
              <h3 className="text-lg font-medium mb-2">5.1 Eligibility</h3>
              <ul className="list-disc pl-6 space-y-2 mb-4">
                <li>Sellers must complete the verification process before listing any products or services.</li>
                <li>StardomApp reserves the right to approve or deny any seller applications.</li>
              </ul>

              <h3 className="text-lg font-medium mb-2">5.2 Listing Items</h3>
              <ul className="list-disc pl-6 space-y-2 mb-4">
                <li>Sellers can list Merchandise, NFTs, Subscriptions, Live Experiences, Digital Goods, and Courses.</li>
                <li>All listings must include accurate descriptions, pricing, and availability.</li>
              </ul>

              <h3 className="text-lg font-medium mb-2">5.3 Fees and Payments</h3>
              <ul className="list-disc pl-6 space-y-2 mb-4">
                <li>StardomApp charges a transaction fee for every successful sale.</li>
                <li>Fees will be outlined in the Seller Dashboard.</li>
                <li>Payments will be processed securely through integrated third-party payment providers.</li>
              </ul>
            </section>

            {/* Additional sections... */}

            <section>
              <h2 className="text-xl font-semibold mb-4">15. Contact Us</h2>
              <p>
                If you have any questions or concerns about these Terms, please contact us at:{' '}
                <a href="mailto:support@stardom.com" className="text-[#F8CA7E] hover:underline">
                  support@stardom.com
                </a>
              </p>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}