import React, { forwardRef } from 'react';

interface TickerContentProps {
  children: React.ReactNode;
  textColor: string;
}

export const TickerContent = forwardRef<HTMLDivElement, TickerContentProps>(
  ({ children, textColor }, ref) => (
    <div
      ref={ref}
      className="ticker-content"
      style={{ color: textColor }}
    >
      {children}
    </div>
  )
);

TickerContent.displayName = 'TickerContent';