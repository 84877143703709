import React from 'react';

interface StoryProgressProps {
  totalStories: number;
  currentIndex: number;
  progress: number;
}

export function StoryProgress({ totalStories, currentIndex, progress }: StoryProgressProps) {
  return (
    <div className="flex gap-1 px-2 absolute top-3 left-0 right-0 z-20">
      {Array.from({ length: totalStories }).map((_, index) => (
        <div
          key={index}
          className="h-0.5 flex-1 bg-white/30 overflow-hidden"
        >
          <div
            className="h-full bg-white transition-transform duration-100 ease-linear origin-left"
            style={{
              transform: `scaleX(${
                index === currentIndex
                  ? progress / 100
                  : index < currentIndex
                  ? 1
                  : 0
              })`
            }}
          />
        </div>
      ))}
    </div>
  );
}