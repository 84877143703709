import OpenAI from 'openai';
import { searchRateLimiter } from '../utils/rateLimiter';
import { ERROR_MESSAGES } from '../utils/constants';
import { CELEBRITY_IMAGES } from '../types/celebrity';

interface AIResponse {
  text: string;
  images?: string[];
  links?: {
    instagram?: string;
    twitter?: string;
    tiktok?: string;
    website?: string;
  };
  sections: {
    identity: string;
    recentProjects: string[];
    socialMedia: {
      platform: string;
      username: string;
      url: string;
      icon: string;
    }[];
  };
}

export class StardomAIService {
  private static instance: StardomAIService;
  private openai: OpenAI;

  private constructor() {
    this.openai = new OpenAI({
      apiKey: import.meta.env.VITE_OPENAI_API_KEY,
      dangerouslyAllowBrowser: true
    });
  }

  static getInstance(): StardomAIService {
    if (!StardomAIService.instance) {
      StardomAIService.instance = new StardomAIService();
    }
    return StardomAIService.instance;
  }

  async getCelebrityInfo(query: string): Promise<AIResponse> {
    try {
      if (!searchRateLimiter.canMakeRequest()) {
        throw new Error(ERROR_MESSAGES.RATE_LIMIT);
      }

      searchRateLimiter.addRequest();

      // Find matching celebrity images
      const images: string[] = [];
      Object.entries(CELEBRITY_IMAGES).forEach(([name, imageUrl]) => {
        if (query.toLowerCase().includes(name.toLowerCase())) {
          images.push(imageUrl);
        }
      });

      // Get AI response with structured format
      const completion = await this.openai.chat.completions.create({
        model: "gpt-4-turbo-preview",
        messages: [
          {
            role: "system",
            content: `You are StardomAI, a knowledgeable assistant specializing in celebrities. 
            Structure your responses in the following JSON format:
            {
              "identity": "A bold, concise description of who they are",
              "recentProjects": [
                "List of their latest projects, releases, or ventures",
                "Include dates where relevant"
              ],
              "socialMedia": [
                {
                  "platform": "Platform name (Instagram/Twitter/TikTok)",
                  "username": "@handle",
                  "url": "Full profile URL",
                  "followers": "Follower count if known"
                }
              ]
            }
            
            Keep the information factual and up-to-date.
            For social media, only include verified accounts.`
          },
          {
            role: "user",
            content: query
          }
        ],
        response_format: { type: "json_object" },
        temperature: 0.7,
        max_tokens: 500
      });

      const response = JSON.parse(completion.choices[0].message.content || '{}');

      // Format the response into sections
      const formattedResponse = {
        text: this.formatResponseText(response),
        images,
        sections: {
          identity: response.identity,
          recentProjects: response.recentProjects || [],
          socialMedia: (response.socialMedia || []).map((social: any) => ({
            platform: social.platform,
            username: social.username,
            url: social.url,
            icon: this.getSocialIcon(social.platform)
          }))
        },
        links: this.extractSocialLinks(response.socialMedia || [])
      };

      return formattedResponse;
    } catch (error) {
      console.error('StardomAI Error:', error);
      throw error;
    }
  }

  private formatResponseText(response: any): string {
    let text = `**${response.identity}**\n\n`;
    
    if (response.recentProjects?.length) {
      text += '**Recent Projects:**\n';
      response.recentProjects.forEach((project: string) => {
        text += `• ${project}\n`;
      });
      text += '\n';
    }

    if (response.socialMedia?.length) {
      text += '**Social Media:**\n';
      response.socialMedia.forEach((social: any) => {
        text += `• ${social.platform}: ${social.username}${social.followers ? ` (${social.followers} followers)` : ''}\n`;
      });
    }

    return text;
  }

  private getSocialIcon(platform: string): string {
    const platformLower = platform.toLowerCase();
    if (platformLower.includes('instagram')) return 'instagram';
    if (platformLower.includes('twitter')) return 'twitter';
    if (platformLower.includes('tiktok')) return 'tiktok';
    return 'link';
  }

  private extractSocialLinks(socialMedia: any[]): Record<string, string> {
    const links: Record<string, string> = {};
    socialMedia.forEach((social) => {
      const platform = social.platform.toLowerCase();
      if (platform.includes('instagram')) links.instagram = social.url;
      if (platform.includes('twitter')) links.twitter = social.url;
      if (platform.includes('tiktok')) links.tiktok = social.url;
      if (platform.includes('website')) links.website = social.url;
    });
    return links;
  }
}