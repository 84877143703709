import React from 'react';
import { Bell, MessageCircle } from 'lucide-react';
import { ThemeToggle } from '../common/ThemeToggle';
import { UserMenu } from '../common/UserMenu';
import { CartDropdown } from '../cart/CartDropdown';
import { Logo } from '../common/Logo';
import { BackButton } from '../navigation/BackButton';
import { useLocation } from 'react-router-dom';

export function Header() {
  const location = useLocation();
  const showBackButton = location.pathname.includes('/celebrity/') || 
                        (location.pathname !== '/dashboard' && !location.pathname.includes('/login') && !location.pathname.includes('/signup'));

  const [showMessages, setShowMessages] = React.useState(false);

  return (
    <header className="sticky top-0 z-50 bg-white dark:bg-gray-800 border-b border-gray-200 dark:border-gray-700 px-4 sm:px-8 py-3 transition-colors">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-4">
          <div className="lg:hidden">
            <Logo size="medium" />
          </div>
          {showBackButton && <BackButton />}
        </div>
        <div className="flex items-center gap-4">
          <ThemeToggle />
          <div className="relative">
            <button 
              onClick={() => setShowMessages(!showMessages)}
              className="p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full transition-colors"
            >
              <MessageCircle className="w-5 h-5 text-gray-700 dark:text-gray-300" />
            </button>
            {showMessages && (
              <div className="absolute right-0 mt-2 w-64 bg-white dark:bg-gray-800 rounded-lg shadow-lg p-4 text-sm text-gray-600 dark:text-gray-400">
                No messages at the moment.
              </div>
            )}
          </div>
          <button className="p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full transition-colors">
            <Bell className="w-5 h-5 text-gray-700 dark:text-gray-300" />
          </button>
          <CartDropdown />
          <UserMenu />
        </div>
      </div>
    </header>
  );
}