import React from 'react';
import { CheckCircle2, X } from 'lucide-react';
import { Link } from 'react-router-dom';

const suggestedCelebrities = [
  {
    name: "Leonardo DiCaprio",
    username: "leonardodicaprio",
    imageUrl: "https://ddlvbtbyvlqpiizexmlr.supabase.co/storage/v1/object/sign/product-images/recommended/American-actor-Leonardo-DiCaprio-2016.webp?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJwcm9kdWN0LWltYWdlcy9yZWNvbW1lbmRlZC9BbWVyaWNhbi1hY3Rvci1MZW9uYXJkby1EaUNhcHJpby0yMDE2LndlYnAiLCJpYXQiOjE3MzMyNjk5MTksImV4cCI6MTc2NDgwNTkxOX0.qz3ACpQ0_QAWNA439IRKNFfn9fQauesSTar9lgufD1U",
    verified: true,
    description: "Actor and environmentalist"
  },
  {
    name: "Brad Pitt",
    username: "bradpitt",
    imageUrl: "https://ddlvbtbyvlqpiizexmlr.supabase.co/storage/v1/object/sign/product-images/recommended/Brad_Pitt_2019_by_Glenn_Francis.jpg?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJwcm9kdWN0LWltYWdlcy9yZWNvbW1lbmRlZC9CcmFkX1BpdHRfMjAxOV9ieV9HbGVubl9GcmFuY2lzLmpwZyIsImlhdCI6MTczMzI2OTk1MiwiZXhwIjoxNzY0ODA1OTUyfQ.3WsDWQlJpvCulaSp9Xb2i693vkv-S7c67sctZwrmUxM",
    verified: true,
    description: "Actor and producer"
  },
  {
    name: "Johnny Depp",
    username: "johnnydepp",
    imageUrl: "https://ddlvbtbyvlqpiizexmlr.supabase.co/storage/v1/object/sign/product-images/recommended/featured_johnny-depp-cannes-film-festival-2023.webp?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJwcm9kdWN0LWltYWdlcy9yZWNvbW1lbmRlZC9mZWF0dXJlZF9qb2hubnktZGVwcC1jYW5uZXMtZmlsbS1mZXN0aXZhbC0yMDIzLndlYnAiLCJpYXQiOjE3MzMyNjk5ODMsImV4cCI6MTc2NDgwNTk4M30.UqtrnDA46u8OprW8-KVpc-AXufWcF9vBTwDfGAhpTg4",
    verified: true,
    description: "Actor and musician"
  },
  {
    name: "Tom Cruise",
    username: "tomcruise",
    imageUrl: "https://ddlvbtbyvlqpiizexmlr.supabase.co/storage/v1/object/sign/product-images/recommended/tom-cruise_tmdb.jpg?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJwcm9kdWN0LWltYWdlcy9yZWNvbW1lbmRlZC90b20tY3J1aXNlX3RtZGIuanBnIiwiaWF0IjoxNzMzMjcwMDI1LCJleHAiOjE3NjQ4MDYwMjV9.ElzEZ1WLRiZmyHfNqApSaBSqFDnKplxWqX-WkOt_G8s",
    verified: true,
    description: "Actor and producer"
  },
  {
    name: "Robert Downey Jr.",
    username: "robertdowneyjr",
    imageUrl: "https://ddlvbtbyvlqpiizexmlr.supabase.co/storage/v1/object/sign/product-images/recommended/0101925.webp?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJwcm9kdWN0LWltYWdlcy9yZWNvbW1lbmRlZC8wMTAxOTI1LndlYnAiLCJpYXQiOjE3MzMyNjk4ODEsImV4cCI6MTc2NDgwNTg4MX0.1CYaRzVCH-HYboLnSpnEOwrFP5Z_qkJsegN8kz8KQpY",
    verified: true,
    description: "Actor and producer"
  }
];

export function SuggestionsSection() {
  return (
    <div className="bg-white dark:bg-gray-800 rounded-xl p-6 shadow-sm">
      <div className="flex items-center justify-between mb-4">
        <div>
          <h3 className="text-base font-semibold text-gray-900 dark:text-white">Suggestions For You</h3>
          <p className="text-sm text-gray-500 dark:text-gray-400">Discover more celebrities</p>
        </div>
        <button className="text-[#F8CA7E] text-sm font-semibold hover:text-[#e5b86b]">
          See All
        </button>
      </div>

      <div className="space-y-4">
        {suggestedCelebrities.map((celebrity) => (
          <div key={celebrity.username} className="flex items-center justify-between">
            <Link 
              to={`/dashboard/celebrity/${celebrity.username}`}
              className="flex items-center gap-3 group"
            >
              <div className="w-11 h-11 rounded-full overflow-hidden">
                <img
                  src={celebrity.imageUrl}
                  alt={celebrity.name}
                  className="w-full h-full object-cover group-hover:scale-105 transition-transform duration-200"
                />
              </div>
              <div className="flex-1 min-w-0">
                <div className="flex items-center gap-1">
                  <p className="text-sm font-semibold text-gray-900 dark:text-white truncate">
                    {celebrity.username}
                  </p>
                  {celebrity.verified && (
                    <CheckCircle2 className="w-3.5 h-3.5 text-[#F8CA7E] flex-shrink-0" />
                  )}
                </div>
                <p className="text-xs text-gray-500 dark:text-gray-400 truncate">
                  {celebrity.description}
                </p>
              </div>
            </Link>
            <button className="text-[#F8CA7E] text-xs font-semibold hover:text-[#e5b86b] ml-4">
              Follow
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}