import React from 'react';
import { SalesOverview } from './SalesOverview';
import { AOVGauge } from './AOVGauge';
import { BestSellers } from './BestSellers';
import { Cancellations } from './Cancellations';

export function AdvancedAnalytics() {
  const salesData = {
    today: {
      amount: 17700,
      change: -5.7,
      period: 'vs. yesterday'
    },
    aov: {
      amount: 35.38,
      min: 0,
      max: 90
    },
    bestSellers: [
      { name: 'Rouge lipstick', units: 278 },
      { name: 'Honey lipstick', units: 211 },
      { name: 'Max mascara', units: 115 },
      { name: 'Lip tint', units: 103 },
      { name: 'Brow beautiful', units: 87 },
      { name: 'Lip liner', units: 65 },
      { name: 'Beeswax balm', units: 52 },
      { name: 'Lip scrub', units: 35 }
    ],
    cancellations: {
      total: 15,
      change: 67,
      reasons: [
        { name: 'customer', count: 9 },
        { name: 'declined', count: 2 },
        { name: 'fraud', count: 2 },
        { name: 'inventory', count: 2 }
      ]
    }
  };

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
      <SalesOverview {...salesData.today} />
      <AOVGauge {...salesData.aov} />
      <BestSellers items={salesData.bestSellers} />
      <Cancellations {...salesData.cancellations} />
    </div>
  );
}