import React, { useState, useEffect, useCallback } from 'react';
import { X } from 'lucide-react';
import { StoryProgress } from './StoryProgress';
import { StoryHeader } from './StoryHeader';
import { StoryControls } from './StoryControls';
import { StoryNavigation } from './StoryNavigation';

interface StoryContent {
  type: 'image';
  url: string;
  duration: number;
}

interface Story {
  id: number;
  celebrity: string;
  imageUrl: string;
  title: string;
  content: StoryContent[];
}

interface StoryViewerProps {
  story: Story;
  onClose: () => void;
  onComplete: () => void;
}

export function StoryViewer({ story, onClose, onComplete }: StoryViewerProps) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [progress, setProgress] = useState(0);
  const [isPaused, setIsPaused] = useState(false);
  const [isMuted, setIsMuted] = useState(false);

  const goToNextStory = useCallback(() => {
    if (currentIndex < story.content.length - 1) {
      setCurrentIndex(prev => prev + 1);
      setProgress(0);
    } else {
      onComplete();
    }
  }, [currentIndex, story.content.length, onComplete]);

  const goToPreviousStory = useCallback(() => {
    if (currentIndex > 0) {
      setCurrentIndex(prev => prev - 1);
      setProgress(0);
    }
  }, [currentIndex]);

  useEffect(() => {
    if (isPaused) return;

    const content = story.content[currentIndex];
    const duration = content.duration;
    const startTime = Date.now();
    const interval = setInterval(() => {
      const elapsed = Date.now() - startTime;
      const newProgress = (elapsed / duration) * 100;
      
      if (newProgress >= 100) {
        goToNextStory();
      } else {
        setProgress(newProgress);
      }
    }, 16);

    return () => clearInterval(interval);
  }, [currentIndex, story, goToNextStory, isPaused]);

  const handleTouchStart = (e: React.TouchEvent) => {
    const touch = e.touches[0];
    const x = touch.clientX;
    const width = window.innerWidth;
    
    if (x < width / 2) {
      goToPreviousStory();
    } else {
      goToNextStory();
    }
  };

  return (
    <div 
      className="fixed inset-0 z-50 bg-black flex items-center justify-center"
      onTouchStart={handleTouchStart}
    >
      <button
        onClick={onClose}
        className="absolute top-4 right-4 text-white p-2 hover:bg-white/10 rounded-full transition-colors z-[60]"
      >
        <X className="w-6 h-6" />
      </button>

      <div className="w-full max-w-md">
        <div className="relative aspect-[9/16] bg-black">
          <img
            key={story.content[currentIndex].url}
            src={story.content[currentIndex].url}
            alt={story.title}
            className="w-full h-full object-contain animate-fade-in"
          />
          
          {/* Progress bar with highest z-index */}
          <div className="absolute inset-x-0 top-0 z-[55]">
            <StoryProgress
              totalStories={story.content.length}
              currentIndex={currentIndex}
              progress={progress}
            />
          </div>

          {/* Header below progress bar */}
          <div className="absolute inset-x-0 top-0 pt-8 z-[54]">
            <StoryHeader
              imageUrl={story.imageUrl}
              celebrity={story.celebrity}
              title={story.title}
            />
          </div>

          {/* Controls */}
          <div className="absolute top-8 right-4 z-[54]">
            <StoryControls
              isPaused={isPaused}
              isMuted={isMuted}
              onPauseToggle={() => setIsPaused(!isPaused)}
              onMuteToggle={() => setIsMuted(!isMuted)}
            />
          </div>

          <StoryNavigation
            onPrevious={goToPreviousStory}
            onNext={goToNextStory}
            showPrevious={currentIndex > 0}
            showNext={currentIndex < story.content.length - 1}
          />

          {/* Touch areas for navigation */}
          <div className="absolute inset-0 flex z-[52]">
            <div className="w-1/2 h-full" onClick={goToPreviousStory} />
            <div className="w-1/2 h-full" onClick={goToNextStory} />
          </div>
        </div>
      </div>
    </div>
  );
}