import React, { useState, useEffect } from 'react';
import { ArrowUp } from 'lucide-react';

export function PerformanceChart() {
  const [data, setData] = useState<{ x: number; y: number }[]>([]);
  const [tooltip, setTooltip] = useState<{ x: number; y: number; value: string } | null>(null);

  useEffect(() => {
    // Generate simplified bullish data
    let value = 1000;
    const points = Array.from({ length: 20 }, (_, i) => {
      value += Math.random() * 30 - 10;
      return { x: i, y: value };
    });
    setData(points);
  }, []);

  if (!data.length) return null;

  const maxY = Math.max(...data.map(p => p.y));
  const minY = Math.min(...data.map(p => p.y));
  const height = 200;
  const width = 600;

  // Simple line path
  const points = data.map((p, i) => (
    `${i === 0 ? 'M' : 'L'} ${(p.x / 19) * width} ${height - ((p.y - minY) / (maxY - minY)) * height}`
  )).join(' ');

  const gain = ((data[data.length - 1].y - data[0].y) / data[0].y * 100).toFixed(1);

  return (
    <div className="relative">
      <div className="absolute top-2 right-2 flex items-center gap-1 text-green-500">
        <ArrowUp className="w-4 h-4" />
        <span>+{gain}%</span>
      </div>
      <svg width="100%" height={height} className="overflow-visible">
        <path
          d={points}
          fill="none"
          stroke="#F8CA7E"
          strokeWidth="2"
        />
        {data.map((point, i) => (
          <circle
            key={i}
            cx={(point.x / 19) * width}
            cy={height - ((point.y - minY) / (maxY - minY)) * height}
            r="4"
            className="fill-white stroke-[#F8CA7E] cursor-pointer hover:r-6 transition-all"
            onMouseEnter={() => setTooltip({
              x: (point.x / 19) * width,
              y: height - ((point.y - minY) / (maxY - minY)) * height,
              value: `$${point.y.toFixed(2)}`
            })}
            onMouseLeave={() => setTooltip(null)}
          />
        ))}
      </svg>
      {tooltip && (
        <div 
          className="absolute bg-white dark:bg-gray-800 px-2 py-1 rounded shadow-lg text-sm"
          style={{ left: tooltip.x, top: tooltip.y - 30 }}
        >
          {tooltip.value}
        </div>
      )}
    </div>
  );
}