import React from 'react';

interface StoryHeaderProps {
  imageUrl: string;
  celebrity: string;
  title: string;
}

export function StoryHeader({ imageUrl, celebrity, title }: StoryHeaderProps) {
  return (
    <div className="absolute top-6 left-4 flex items-center gap-3 z-20">
      <div className="w-8 h-8 rounded-full overflow-hidden ring-2 ring-white/20">
        <img
          src={imageUrl}
          alt={celebrity}
          className="w-full h-full object-cover"
        />
      </div>
      <div>
        <h4 className="text-white font-medium text-sm">{celebrity}</h4>
        <p className="text-white/80 text-xs">{title}</p>
      </div>
    </div>
  );
}