import React from 'react';
import { Calendar } from 'lucide-react';

export function EventsPage() {
  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900 p-6">
      <div className="max-w-7xl mx-auto text-center mt-12">
        <Calendar className="w-16 h-16 text-gray-400 mx-auto mb-4" />
        <h1 className="text-2xl font-bold text-gray-900 dark:text-white mb-2">No Stardom Events</h1>
        <p className="text-gray-600 dark:text-gray-400">Check back later for upcoming events</p>
      </div>
    </div>
  );
}