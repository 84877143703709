import React from 'react';
import { Home, Store, Wallet, DollarSign, Calendar } from 'lucide-react';
import { Logo } from '../common/Logo';
import { useNavigate, useLocation } from 'react-router-dom';
import { CrystalBall } from '../icons/CrystalBall';
import { StarIcon } from '../icons/StarIcon';

const navItems = [
  { id: 'home', icon: Home, label: 'Home', path: '/dashboard' },
  { id: 'estore', icon: Store, label: 'E-store', path: '/dashboard/community' },
  { id: 'stardomai', icon: StarIcon, label: 'Stardom AI', path: '/dashboard/ai' },
  { id: 'wallet', icon: Wallet, label: 'Wallet', path: '/dashboard/wallet' },
  { id: 'events', icon: Calendar, label: 'Events', path: '/dashboard/events' },
  { id: 'starverse', icon: CrystalBall, label: 'Starverse', path: '/dashboard/starverse' },
  { id: 'sell', icon: DollarSign, label: 'Sell', path: '/dashboard/sell' }
];

export function Sidebar() {
  const navigate = useNavigate();
  const location = useLocation();

  const handleNavigation = (path: string) => {
    navigate(path);
  };

  return (
    <div className="w-64 bg-white dark:bg-gray-800 border-r border-gray-200 dark:border-gray-700 px-4 py-6 hidden lg:block">
      <div className="mb-8">
        <Logo size="medium" />
      </div>
      <nav className="space-y-1">
        {navItems.map(({ id, icon: Icon, label, path }) => (
          <button
            key={id}
            onClick={() => handleNavigation(path)}
            className={`w-full flex items-center gap-3 px-4 py-3 rounded-lg text-sm font-medium transition-colors ${
              location.pathname === path
                ? 'bg-black text-white dark:bg-white dark:text-black'
                : 'text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700'
            }`}
          >
            <Icon className="w-5 h-5" />
            {label}
          </button>
        ))}
      </nav>
    </div>
  );
}