import React from 'react';
import { Grid, Users, Film } from 'lucide-react';
import { CELEBRITY_IMAGES } from '../../types/celebrity';
import { ProfileHeader } from '../../components/profile/ProfileHeader';

const RIHANNA = {
  name: "Rihanna",
  username: "rihanna",
  imageUrl: CELEBRITY_IMAGES['Rihanna'],
  coverImage: "https://ddlvbtbyvlqpiizexmlr.supabase.co/storage/v1/object/sign/product-images/Rihanna%20product%20images/21820397_484368995277897_2228104942969683968_n-1024x682.jpg?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJwcm9kdWN0LWltYWdlcy9SaWhhbm5hIHByb2R1Y3QgaW1hZ2VzLzIxODIwMzk3XzQ4NDM2ODk5NTI3Nzg5N18yMjI4MTA0OTQyOTY5NjgzOTY4X24tMTAyNHg2ODIuanBnIiwiaWF0IjoxNzMzNDQ3OTk0LCJleHAiOjE3NjQ5ODM5OTR9.1R-rdzbnQmgaDVipGVhlvYrWNjX3OnLI-ulEZX2_1EY",
  bio: "Entrepreneur, singer, and fashion icon. Shop exclusive Fenty Beauty products and merchandise.",
  location: "Barbados / Los Angeles",
  followers: 152000000,
  following: 1471,
  verified: true,
  socialLinks: {
    website: "https://rihanna.store/collections/apparel",
    instagram: "https://instagram.com/badgalriri",
    twitter: "https://twitter.com/rihanna"
  },
  products: [
    {
      id: "1",
      name: "Rated R Hoodie",
      description: "Classic black hoodie featuring Rated R album artwork",
      price: 65.00,
      imageUrl: "https://ddlvbtbyvlqpiizexmlr.supabase.co/storage/v1/object/sign/product-images/Rihanna%20new%20product%20images/Rated%20R%20Hoodie.webp?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJwcm9kdWN0LWltYWdlcy9SaWhhbm5hIG5ldyBwcm9kdWN0IGltYWdlcy9SYXRlZCBSIEhvb2RpZS53ZWJwIiwiaWF0IjoxNzM2OTg5NTU5LCJleHAiOjE3Njg1MjU1NTl9.XBS0C71aLSkjBdRhcUJNQbaqMvlY4AmXiBVGRsnccQk",
      url: "https://rihanna.store/collections/apparel"
    },
    {
      id: "2",
      name: "Loud T-shirt",
      description: "Red graphic t-shirt featuring Loud album artwork",
      price: 35.00,
      imageUrl: "https://ddlvbtbyvlqpiizexmlr.supabase.co/storage/v1/object/sign/product-images/Rihanna%20new%20product%20images/Loud%20T-shirt.webp?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJwcm9kdWN0LWltYWdlcy9SaWhhbm5hIG5ldyBwcm9kdWN0IGltYWdlcy9Mb3VkIFQtc2hpcnQud2VicCIsImlhdCI6MTczNjk4OTU3OSwiZXhwIjoxNzY4NTI1NTc5fQ.AKzbH5b4-YQunuK2-0DwCURgPDX5sC9lTa3TGw9myGA",
      url: "https://rihanna.store/collections/apparel"
    },
    {
      id: "3",
      name: "A Girl Like Me T-shirt",
      description: "Classic t-shirt featuring A Girl Like Me album artwork",
      price: 35.00,
      imageUrl: "https://ddlvbtbyvlqpiizexmlr.supabase.co/storage/v1/object/sign/product-images/Rihanna%20new%20product%20images/A%20Girl%20Like%20Me%20T-shirt.webp?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJwcm9kdWN0LWltYWdlcy9SaWhhbm5hIG5ldyBwcm9kdWN0IGltYWdlcy9BIEdpcmwgTGlrZSBNZSBULXNoaXJ0LndlYnAiLCJpYXQiOjE3MzY5ODk1ODgsImV4cCI6MTc2ODUyNTU4OH0.pmlRR0fE--pXcy9cF9m1jhsVlHSYUBnVq4g4P3Sgknk",
      url: "https://rihanna.store/collections/apparel"
    },
    {
      id: "4",
      name: "Good Girl Gone Bad T-shirt",
      description: "Black t-shirt featuring Good Girl Gone Bad album artwork",
      price: 35.00,
      imageUrl: "https://ddlvbtbyvlqpiizexmlr.supabase.co/storage/v1/object/sign/product-images/Rihanna%20new%20product%20images/Good%20Girl%20Gone%20Bad%20T-shirt.webp?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJwcm9kdWN0LWltYWdlcy9SaWhhbm5hIG5ldyBwcm9kdWN0IGltYWdlcy9Hb29kIEdpcmwgR29uZSBCYWQgVC1zaGlydC53ZWJwIiwiaWF0IjoxNzM2OTg5NTk2LCJleHAiOjE3Njg1MjU1OTZ9.1-4A6l0R_ymT5xq9dnjawFXN1LZlmtpeS2zkaDAuXMk",
      url: "https://rihanna.store/collections/apparel"
    },
    {
      id: "5",
      name: "Unapologetic Long Sleeve Shirt",
      description: "Long sleeve shirt featuring Unapologetic album artwork",
      price: 45.00,
      imageUrl: "https://ddlvbtbyvlqpiizexmlr.supabase.co/storage/v1/object/sign/product-images/Rihanna%20new%20product%20images/Unapologetic%20Long%20Sleeve%20Shirt.webp?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJwcm9kdWN0LWltYWdlcy9SaWhhbm5hIG5ldyBwcm9kdWN0IGltYWdlcy9VbmFwb2xvZ2V0aWMgTG9uZyBTbGVldmUgU2hpcnQud2VicCIsImlhdCI6MTczNjk4OTYwOCwiZXhwIjoxNzY4NTI1NjA4fQ.ON37ueEv5SPGuZzxJgADsQmA3647eRPHMKNDoAPRAUY",
      url: "https://rihanna.store/collections/apparel"
    },
    {
      id: "6",
      name: "Talk That Talk T-shirt",
      description: "Graphic t-shirt featuring Talk That Talk album artwork",
      price: 35.00,
      imageUrl: "https://ddlvbtbyvlqpiizexmlr.supabase.co/storage/v1/object/sign/product-images/Rihanna%20new%20product%20images/Talk%20That%20Talk%20T-shirt.webp?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJwcm9kdWN0LWltYWdlcy9SaWhhbm5hIG5ldyBwcm9kdWN0IGltYWdlcy9UYWxrIFRoYXQgVGFsayBULXNoaXJ0LndlYnAiLCJpYXQiOjE3MzY5ODk2MTUsImV4cCI6MTc2ODUyNTYxNX0.LGA2s3Q4Tj-HB4tKhVuclUuX7tFXLWFbNp-vYzVLGS4",
      url: "https://rihanna.store/collections/apparel"
    }
  ]
};

export function RihannaProfile() {
  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900">
      <div className="max-w-7xl mx-auto">
        <div className="bg-white dark:bg-gray-800 rounded-xl shadow-lg overflow-hidden my-8 mx-4">
          <ProfileHeader celebrity={RIHANNA} />
          
          <div className="p-8">
            <div className="flex justify-center border-b border-gray-200 dark:border-gray-700 mb-8">
              <button className="px-8 py-4 border-b-2 border-[#F8CA7E] text-[#F8CA7E] flex items-center gap-2">
                <Grid className="w-5 h-5" />
                <span>Products</span>
              </button>
              <button className="px-8 py-4 text-gray-500 dark:text-gray-400 flex items-center gap-2">
                <Users className="w-5 h-5" />
                <span>Community</span>
              </button>
              <button className="px-8 py-4 text-gray-500 dark:text-gray-400 flex items-center gap-2">
                <Film className="w-5 h-5" />
                <span>Media</span>
              </button>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
              {RIHANNA.products?.map((product) => (
                <div key={product.id} className="bg-white dark:bg-gray-800 rounded-lg shadow-sm hover:shadow-md transition-shadow overflow-hidden">
                  <div className="aspect-square relative overflow-hidden">
                    <img
                      src={product.imageUrl}
                      alt={product.name}
                      className="w-full h-full object-cover object-center group-hover:scale-105 transition-transform duration-300"
                    />
                  </div>
                  
                  <div className="p-4">
                    <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-1">
                      {product.name}
                    </h3>
                    <p className="text-sm text-gray-600 dark:text-gray-400 mb-3">
                      {product.description}
                    </p>
                    <div className="flex items-center justify-between">
                      <span className="text-xl font-bold text-gray-900 dark:text-white">
                        ${product.price.toFixed(2)}
                      </span>
                      <a
                        href={product.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="px-4 py-2 bg-[#F8CA7E] text-white rounded-lg hover:bg-[#e5b86b] transition-colors text-sm font-medium"
                      >
                        Shop Now
                      </a>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}