import React, { forwardRef } from 'react';

interface TickerContainerProps {
  children: React.ReactNode;
  backgroundColor: string;
}

export const TickerContainer = forwardRef<HTMLDivElement, TickerContainerProps>(
  ({ children, backgroundColor }, ref) => (
    <div
      ref={ref}
      className="fixed bottom-0 left-0 right-0 h-8 md:h-6 overflow-hidden font-sans z-[9999] mb-16 md:mb-0"
      style={{ backgroundColor }}
    >
      {children}
    </div>
  )
);

TickerContainer.displayName = 'TickerContainer';