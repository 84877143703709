import React, { useState, useEffect } from 'react';
import { CELEBRITY_IMAGES } from '../../types/celebrity';
import { StoryViewer } from './StoryViewer';

const stories = [
  {
    id: 1,
    celebrity: "Taylor Swift",
    imageUrl: CELEBRITY_IMAGES['Taylor Swift'],
    title: "Eras Tour Highlights",
    content: [
      {
        type: 'image',
        url: 'https://images.unsplash.com/photo-1514525253161-7a46d19cd819',
        duration: 10000
      },
      {
        type: 'image',
        url: 'https://images.unsplash.com/photo-1540039155733-5bb30b53aa14',
        duration: 10000
      }
    ]
  },
  {
    id: 2,
    celebrity: "Rihanna",
    imageUrl: CELEBRITY_IMAGES['Rihanna'],
    title: "Fenty Beauty Launch",
    content: [
      {
        type: 'image',
        url: 'https://images.unsplash.com/photo-1596704017254-9b121068fb31',
        duration: 10000
      }
    ]
  },
  {
    id: 3,
    celebrity: "Cristiano Ronaldo",
    imageUrl: CELEBRITY_IMAGES['Cristiano Ronaldo'],
    title: "Match Day",
    content: [
      {
        type: 'image',
        url: 'https://images.unsplash.com/photo-1579952363873-27f3bade9f55',
        duration: 10000
      }
    ]
  },
  {
    id: 4,
    celebrity: "Beyoncé",
    imageUrl: CELEBRITY_IMAGES['Beyoncé'],
    title: "Renaissance Tour",
    content: [
      {
        type: 'image',
        url: 'https://images.unsplash.com/photo-1514525253161-7a46d19cd819',
        duration: 10000
      }
    ]
  }
];

export function StoriesSection() {
  const [selectedStory, setSelectedStory] = useState<number | null>(null);
  const [viewedStories, setViewedStories] = useState<Set<number>>(new Set());

  const handleStoryClick = (storyId: number) => {
    setSelectedStory(storyId);
  };

  const handleStoryComplete = (storyId: number) => {
    setViewedStories(prev => new Set([...prev, storyId]));
    setSelectedStory(null);
  };

  return (
    <>
      <div className="flex gap-4 overflow-x-auto pb-2 scrollbar-hide">
        {stories.map((story) => (
          <button
            key={story.id}
            onClick={() => handleStoryClick(story.id)}
            className="flex-shrink-0 group"
          >
            <div className="relative w-20 h-20 mb-1">
              <div className={`absolute inset-0 rounded-full p-[2px] ${
                viewedStories.has(story.id)
                  ? 'bg-gray-200 dark:bg-gray-700'
                  : 'bg-gradient-to-tr from-[#F8CA7E] to-[#e5b86b]'
              }`}>
                <div className="w-full h-full rounded-full p-[2px] bg-white dark:bg-gray-800">
                  <img
                    src={story.imageUrl}
                    alt={story.celebrity}
                    className="w-full h-full rounded-full object-cover"
                  />
                </div>
              </div>
            </div>
            <p className="text-xs text-center text-gray-700 dark:text-gray-300 truncate w-20">
              {story.title}
            </p>
          </button>
        ))}
      </div>

      {selectedStory !== null && (
        <StoryViewer
          story={stories.find(s => s.id === selectedStory)!}
          onClose={() => setSelectedStory(null)}
          onComplete={() => handleStoryComplete(selectedStory)}
        />
      )}
    </>
  );
}