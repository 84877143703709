import React from 'react';
import { Home, Star, Store, Wallet, Calendar } from 'lucide-react';
import { useNavigate, useLocation } from 'react-router-dom';

const navItems = [
  { icon: Home, label: 'Home', path: '/dashboard' },
  { icon: Store, label: 'E-store', path: '/dashboard/community' },
  { icon: Star, label: 'AI', path: '/dashboard/ai' },
  { icon: Wallet, label: 'Wallet', path: '/dashboard/wallet' },
  { icon: Calendar, label: 'Events', path: '/dashboard/events' }
];

export function BottomNavigation() {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <nav className="lg:hidden fixed bottom-0 left-0 right-0 bg-white dark:bg-gray-800 border-t border-gray-200 dark:border-gray-700 z-50">
      <div className="flex justify-around items-center h-16">
        {navItems.map(({ icon: Icon, label, path }) => (
          <button
            key={label}
            onClick={() => navigate(path)}
            className={`flex flex-col items-center justify-center w-full h-full transition-colors ${
              location.pathname === path
                ? 'text-[#F8CA7E]'
                : 'text-gray-600 dark:text-gray-400 hover:text-[#F8CA7E] dark:hover:text-[#F8CA7E]'
            }`}
          >
            <Icon className="h-5 w-5" />
            <span className="text-xs mt-1">{label}</span>
          </button>
        ))}
      </div>
    </nav>
  );
}