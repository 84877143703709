import React from 'react';

export function StarversePage() {
  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900 p-6">
      <div className="max-w-7xl mx-auto mt-8">
        <div className="text-center">
          <h1 className="text-3xl font-bold mb-6 text-gray-900 dark:text-white">Welcome to Starverse</h1>
        </div>
        <div className="bg-white dark:bg-gray-800 rounded-xl shadow-lg overflow-hidden">
          <div className="aspect-video">
            <video
              className="w-full h-full object-cover"
              controls
              autoPlay
              muted
              loop
            >
              <source
                src="https://ddlvbtbyvlqpiizexmlr.supabase.co/storage/v1/object/sign/product-images/Starverse/y2mate.com%20-%20Chanel%20Virtual%20Retail%20Store%20The%20Ultimate%20Metaverse%20Shopping%20Experience_v720P.mp4?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJwcm9kdWN0LWltYWdlcy9TdGFydmVyc2UveTJtYXRlLmNvbSAtIENoYW5lbCBWaXJ0dWFsIFJldGFpbCBTdG9yZSBUaGUgVWx0aW1hdGUgTWV0YXZlcnNlIFNob3BwaW5nIEV4cGVyaWVuY2VfdjcyMFAubXA0IiwiaWF0IjoxNzMzNzc3NjkxLCJleHAiOjE3NjUzMTM2OTF9.x0Gruka4fva8JPHPG3GuiwkVhLvmI8XJ2pHZCy7GKuw"
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          </div>
          <div className="p-6">
            <h2 className="text-xl font-bold mb-2 text-gray-900 dark:text-white">
              Experience Virtual Shopping
            </h2>
            <p className="text-gray-600 dark:text-gray-400">
              Step into the future of shopping with our immersive virtual retail experience.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}