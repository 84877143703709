import React from 'react';
import { SearchSuggestions } from '../search/SearchSuggestions';
import { useNavigate } from 'react-router-dom';

export function CommunityLayout() {
  const navigate = useNavigate();

  const handleCelebritySelect = (username: string) => {
    const celebritySlug = username.toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/\s+/g, '')
      .replace(/[^a-z0-9]/g, '');
    navigate(`/dashboard/celebrity/${celebritySlug}`);
  };

  return (
    <div className="min-h-[calc(100vh-64px)] bg-gray-50 dark:bg-gray-900">
      <div className="p-8">
        <SearchSuggestions onSelect={handleCelebritySelect} />
      </div>
    </div>
  );
}