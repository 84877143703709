import React from 'react';
import { XCircle } from 'lucide-react';

interface CancellationReason {
  name: string;
  count: number;
}

interface CancellationsProps {
  total: number;
  change: number;
  reasons: CancellationReason[];
}

export function Cancellations({ total, change, reasons }: CancellationsProps) {
  return (
    <div className="bg-gray-900 rounded-xl p-6">
      <div className="flex items-center gap-3 mb-4">
        <XCircle className="w-6 h-6 text-[#F8CA7E]" />
        <h3 className="text-xl font-semibold text-white">Cancellations</h3>
      </div>
      <div className="mb-4">
        <div className="text-4xl font-bold text-white">{total}</div>
        <div className="text-sm text-gray-400 mt-1">
          Total - this month
          <span className="text-red-400 ml-2">↑ {change}% vs. last month</span>
        </div>
      </div>
      <div className="space-y-2">
        {reasons.map((reason, index) => (
          <div key={index} className="flex justify-between items-center">
            <span className="text-gray-300">{reason.name}</span>
            <span className="text-white font-medium">{reason.count}</span>
          </div>
        ))}
      </div>
    </div>
  );
}