import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

export function FixedFooter() {
  const [showFooter, setShowFooter] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      
      // Show footer when within 20px of bottom
      const isBottom = windowHeight + scrollTop >= documentHeight - 20;
      setShowFooter(isBottom);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  if (!showFooter) return null;

  return (
    <footer className="fixed bottom-0 left-0 right-0 bg-white dark:bg-gray-800 border-t border-gray-200 dark:border-gray-700 py-4 px-6 z-50 transition-opacity duration-200">
      <div className="max-w-7xl mx-auto flex justify-center items-center gap-6 text-sm text-gray-600 dark:text-gray-400">
        <Link to="/terms" className="hover:text-[#F8CA7E] transition-colors">
          Terms of Service
        </Link>
        <span className="text-gray-300 dark:text-gray-600">•</span>
        <Link to="/privacy" className="hover:text-[#F8CA7E] transition-colors">
          Privacy Policy
        </Link>
        <span className="text-gray-300 dark:text-gray-600">•</span>
        <a 
          href="mailto:support@stardom.com" 
          className="hover:text-[#F8CA7E] transition-colors"
        >
          Contact Us
        </a>
      </div>
    </footer>
  );
}