import React from 'react';
import { Package } from 'lucide-react';

interface BestSeller {
  name: string;
  units: number;
}

interface BestSellersProps {
  items: BestSeller[];
}

export function BestSellers({ items }: BestSellersProps) {
  return (
    <div className="bg-gray-900 rounded-xl p-6">
      <div className="flex items-center gap-3 mb-4">
        <Package className="w-6 h-6 text-[#F8CA7E]" />
        <h3 className="text-xl font-semibold text-white">Best sellers - this week</h3>
      </div>
      <div className="space-y-3">
        {items.map((item, index) => (
          <div key={index} className="flex justify-between items-center">
            <span className="text-gray-300">{item.name}</span>
            <span className="text-white font-medium">{item.units}</span>
          </div>
        ))}
      </div>
    </div>
  );
}