import React from 'react';
import { Volume2, VolumeX, Pause, Play } from 'lucide-react';

interface StoryControlsProps {
  isPaused: boolean;
  isMuted: boolean;
  onPauseToggle: () => void;
  onMuteToggle: () => void;
}

export function StoryControls({ isPaused, isMuted, onPauseToggle, onMuteToggle }: StoryControlsProps) {
  return (
    <div className="absolute top-4 right-4 flex items-center gap-2 z-20">
      <button
        onClick={onPauseToggle}
        className="text-white hover:text-white/80 transition-colors"
      >
        {isPaused ? (
          <Play className="w-5 h-5" />
        ) : (
          <Pause className="w-5 h-5" />
        )}
      </button>
      <button
        onClick={onMuteToggle}
        className="text-white hover:text-white/80 transition-colors"
      >
        {isMuted ? (
          <VolumeX className="w-5 h-5" />
        ) : (
          <Volume2 className="w-5 h-5" />
        )}
      </button>
    </div>
  );
}