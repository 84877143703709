import React from 'react';
import { TickerContainer } from './TickerContainer';
import { TickerContent } from './TickerContent';
import './styles.css';

export interface NewsTickerProps {
  message: string;
  backgroundColor?: string;
  textColor?: string;
}

export function NewsTicker({
  message,
  backgroundColor = 'rgb(0 0 0 / 0.8)',
  textColor = 'white'
}: NewsTickerProps) {
  // Add single space and bullet for seamless loop
  const displayMessage = `${message} • `;
  
  return (
    <TickerContainer backgroundColor={backgroundColor}>
      <div className="ticker-wrapper">
        <TickerContent textColor={textColor}>
          {/* Duplicate message for seamless loop */}
          {displayMessage.repeat(2)}
        </TickerContent>
      </div>
    </TickerContainer>
  );
}