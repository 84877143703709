import React from 'react';
import { ArrowLeft } from 'lucide-react';
import { useNavigate, useLocation } from 'react-router-dom';

export function BackButton() {
  const navigate = useNavigate();
  const location = useLocation();
  
  // Don't show on main dashboard, login, or signup pages
  if (location.pathname === '/dashboard' || 
      location.pathname === '/login' || 
      location.pathname === '/signup') {
    return null;
  }

  return (
    <button
      onClick={() => navigate(-1)}
      className="fixed top-24 left-4 lg:left-72 z-40 p-3 bg-white/80 dark:bg-gray-800/80 backdrop-blur-sm rounded-full shadow-lg hover:bg-[#F8CA7E]/20 dark:hover:bg-[#F8CA7E]/20 transition-all duration-200"
      aria-label="Go back"
    >
      <ArrowLeft className="w-5 h-5 text-gray-700 dark:text-gray-300" />
    </button>
  );
}