import { AuthError, Session } from '@supabase/supabase-js';
import { SupabaseService } from './SupabaseService';

export class AuthService {
  private static instance: AuthService;
  private supabase: SupabaseService;
  private currentSession: Session | null = null;

  private constructor() {
    this.supabase = SupabaseService.getInstance();
    this.initSession();
  }

  static getInstance(): AuthService {
    if (!AuthService.instance) {
      AuthService.instance = new AuthService();
    }
    return AuthService.instance;
  }

  private async initSession() {
    const { data: { session } } = await this.supabase.auth.getSession();
    this.currentSession = session;
  }

  async signInWithPassword(credentials: { email: string; password: string }) {
    try {
      await this.clearSession();
      const { data, error } = await this.supabase.auth.signInWithPassword(credentials);
      if (error) throw error;
      this.currentSession = data.session;
      return { data, error: null };
    } catch (error) {
      console.error('Sign in error:', error);
      return { data: null, error: error as AuthError };
    }
  }

  async signOut() {
    try {
      if (!this.currentSession) {
        await this.clearSession();
        return;
      }
      
      await this.supabase.auth.signOut({ scope: 'local' });
      this.currentSession = null;
      await this.clearSession();
    } catch (error) {
      console.error('Sign out error:', error);
      // Ensure session is cleared even if API call fails
      this.currentSession = null;
      await this.clearSession();
    }
  }

  async getSession() {
    if (!this.currentSession) {
      await this.initSession();
    }
    return { data: { session: this.currentSession }, error: null };
  }

  private async clearSession() {
    const hostname = new URL(import.meta.env.VITE_SUPABASE_URL).hostname;
    const keys = [
      `sb-${hostname}-auth-token`,
      'supabase.auth.token',
      'sb-refresh-token',
      'sb-access-token'
    ];
    
    keys.forEach(key => localStorage.removeItem(key));
    this.currentSession = null;
  }
}