import React, { useState, useRef, useEffect } from 'react';
import { Send, Bot, Loader2, Trash2, Instagram, Twitter, Link as LinkIcon } from 'lucide-react';
import { StardomAIService } from '../../services/StardomAIService';
import { VoiceSynthesisService } from '../../services/VoiceSynthesisService';
import { cn } from '../../utils/cn';

interface Message {
  id: string;
  content: string;
  sender: 'user' | 'ai';
  celebrityImage?: string;
  sections?: {
    identity: string;
    recentProjects: string[];
    socialMedia: {
      platform: string;
      username: string;
      url: string;
      icon: string;
    }[];
  };
}

export function StardomAIChat() {
  const [messages, setMessages] = useState<Message[]>([{
    id: 'welcome',
    content: "Hi! I'm StardomAI, your virtual assistant. Ask me anything about celebrities, their products, or upcoming events!",
    sender: 'ai'
  }]);
  const [input, setInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const clearChat = () => {
    setMessages([{
      id: 'welcome',
      content: "Hi! I'm StardomAI, your virtual assistant. Ask me anything about celebrities, their products, or upcoming events!",
      sender: 'ai'
    }]);
    setInput('');
    setError(null);
  };

  const handleSubmit = async (e?: React.FormEvent) => {
    e?.preventDefault();
    if (!input.trim() || isLoading) return;

    const userMessage: Message = {
      id: Date.now().toString(),
      content: input,
      sender: 'user'
    };

    setMessages(prev => [...prev, userMessage]);
    setInput('');
    setIsLoading(true);
    setError(null);

    try {
      const aiService = StardomAIService.getInstance();
      const voiceService = VoiceSynthesisService.getInstance();
      const response = await aiService.getCelebrityInfo(input);

      const aiMessage: Message = {
        id: (Date.now() + 1).toString(),
        content: response.text,
        sender: 'ai',
        celebrityImage: response.images?.[0],
        sections: response.sections
      };

      setMessages(prev => [...prev, aiMessage]);
      voiceService.speak(response.text);
    } catch (error) {
      setError('Oops, something went wrong. Please try again.');
      console.error('StardomAI Error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSubmit();
    }
  };

  const renderSocialMediaIcon = (platform: string) => {
    switch (platform.toLowerCase()) {
      case 'instagram':
        return <Instagram className="w-4 h-4" />;
      case 'twitter':
        return <Twitter className="w-4 h-4" />;
      default:
        return <LinkIcon className="w-4 h-4" />;
    }
  };

  return (
    <div className="flex flex-col h-[calc(100vh-64px)]">
      {/* Header */}
      <div className="bg-white dark:bg-gray-800 border-b border-gray-200 dark:border-gray-700 p-4">
        <div className="flex items-center justify-center max-w-3xl mx-auto relative">
          <h1 className="text-xl font-semibold dark:text-white">StardomAI Chat</h1>
          <button
            onClick={clearChat}
            className="absolute right-0 p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full transition-colors"
            title="Clear chat"
          >
            <Trash2 className="w-5 h-5 text-gray-500 dark:text-gray-400" />
          </button>
        </div>
      </div>

      {/* Messages */}
      <div className="flex-1 overflow-y-auto p-4 bg-gray-50 dark:bg-gray-900">
        <div className="max-w-3xl mx-auto space-y-4">
          {messages.map((message) => (
            <div
              key={message.id}
              className={cn(
                "flex gap-3",
                message.sender === 'user' ? 'justify-end' : 'justify-start'
              )}
            >
              {message.sender === 'ai' && (
                <div className="flex-shrink-0 w-8 h-8 rounded-full bg-[#F8CA7E] flex items-center justify-center">
                  <Bot className="w-5 h-5 text-black" />
                </div>
              )}
              <div
                className={cn(
                  "max-w-[80%] rounded-lg p-4",
                  message.sender === 'user'
                    ? 'bg-[#F8CA7E] text-white'
                    : 'bg-white dark:bg-gray-800 shadow-sm'
                )}
              >
                {message.celebrityImage && (
                  <img
                    src={message.celebrityImage}
                    alt="Celebrity"
                    className="w-32 h-32 rounded-lg object-cover mb-3"
                  />
                )}
                
                {message.sections ? (
                  <div className="space-y-4">
                    <div className="text-gray-700 dark:text-gray-300 font-bold">
                      {message.sections.identity}
                    </div>
                    
                    {message.sections.recentProjects.length > 0 && (
                      <div>
                        <h4 className="font-semibold mb-2 text-gray-700 dark:text-gray-300">
                          Recent Projects
                        </h4>
                        <ul className="list-disc pl-4 space-y-1">
                          {message.sections.recentProjects.map((project, index) => (
                            <li key={index} className="text-gray-600 dark:text-gray-400">
                              {project}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                    
                    {message.sections.socialMedia.length > 0 && (
                      <div>
                        <h4 className="font-semibold mb-2 text-gray-700 dark:text-gray-300">
                          Social Media
                        </h4>
                        <div className="space-y-2">
                          {message.sections.socialMedia.map((social, index) => (
                            <a
                              key={index}
                              href={social.url}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="flex items-center gap-2 text-[#F8CA7E] hover:text-[#e5b86b] transition-colors"
                            >
                              {renderSocialMediaIcon(social.platform)}
                              <span>{social.username}</span>
                            </a>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <p className={cn(
                    "text-sm whitespace-pre-line",
                    message.sender === 'user'
                      ? 'text-white'
                      : 'text-gray-700 dark:text-gray-300'
                  )}>
                    {message.content}
                  </p>
                )}
              </div>
            </div>
          ))}
          {isLoading && (
            <div className="flex items-center gap-3">
              <div className="w-8 h-8 rounded-full bg-[#F8CA7E] flex items-center justify-center">
                <Loader2 className="w-5 h-5 text-white animate-spin" />
              </div>
              <div className="bg-white dark:bg-gray-800 rounded-lg p-4 shadow-sm">
                <p className="text-sm text-gray-500 dark:text-gray-400">
                  Thinking...
                </p>
              </div>
            </div>
          )}
          {error && (
            <div className="flex items-center gap-3">
              <div className="w-8 h-8 rounded-full bg-red-500 flex items-center justify-center">
                <Bot className="w-5 h-5 text-white" />
              </div>
              <div className="bg-red-50 dark:bg-red-900/20 rounded-lg p-4">
                <p className="text-sm text-red-600 dark:text-red-400">{error}</p>
              </div>
            </div>
          )}
          <div ref={messagesEndRef} />
        </div>
      </div>

      {/* Input */}
      <div className="bg-white dark:bg-gray-800 border-t border-gray-200 dark:border-gray-700 p-4">
        <form onSubmit={handleSubmit} className="max-w-3xl mx-auto">
          <div className="relative">
            <textarea
              ref={inputRef}
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onKeyPress={handleKeyPress}
              placeholder="Ask StardomAI anything..."
              className="w-full pl-4 pr-12 py-3 bg-gray-100 dark:bg-gray-700 border-0 rounded-lg resize-none focus:ring-2 focus:ring-[#F8CA7E] dark:text-white"
              rows={1}
              disabled={isLoading}
            />
            <button
              type="submit"
              disabled={isLoading || !input.trim()}
              className="absolute right-2 top-1/2 -translate-y-1/2 p-2 text-gray-400 hover:text-[#F8CA7E] disabled:opacity-50 disabled:hover:text-gray-400"
            >
              <Send className="w-5 h-5" />
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}