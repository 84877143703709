import React from 'react';
import { TrendingUp, Clock } from 'lucide-react';
import { PerformanceChart } from './PerformanceChart';

export function WalletStats() {
  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
      <div className="bg-white dark:bg-gray-800 rounded-xl shadow-sm p-6 overflow-hidden">
        <div className="flex items-center gap-3 mb-6">
          <div className="p-3 bg-green-100 dark:bg-green-900/20 rounded-lg">
            <TrendingUp className="w-6 h-6 text-green-600 dark:text-green-400" />
          </div>
          <h3 className="text-lg font-semibold text-gray-900 dark:text-white">Performance</h3>
        </div>
        
        <div className="h-64 w-full">
          <PerformanceChart />
        </div>
      </div>

      <div className="bg-white dark:bg-gray-800 rounded-xl shadow-sm p-6">
        <div className="flex items-center gap-3 mb-6">
          <div className="p-3 bg-blue-100 dark:bg-blue-900/20 rounded-lg">
            <Clock className="w-6 h-6 text-blue-600 dark:text-blue-400" />
          </div>
          <h3 className="text-lg font-semibold text-gray-900 dark:text-white">Transaction History</h3>
        </div>

        <div className="space-y-4">
          <div className="text-center text-gray-400 py-8">
            No recent transactions
          </div>
        </div>
      </div>
    </div>
  );
}