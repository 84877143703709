import React from 'react';
import { WalletOverview } from '../../components/wallet/WalletOverview';
import { WalletStats } from '../../components/wallet/WalletStats';

export function WalletPage() {
  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900 p-6">
      <div className="max-w-7xl mx-auto space-y-6">
        <div className="text-center">
          <h1 className="text-3xl font-bold text-gray-900 dark:text-white mb-8">Wallet</h1>
        </div>
        
        {/* Main wallet overview */}
        <WalletOverview />

        {/* Stats and charts */}
        <WalletStats />
      </div>
    </div>
  );
}