import React, { useState } from 'react';
import { 
  Shirt, 
  Blocks, 
  CreditCard, 
  Ticket, 
  Gavel, 
  Play, 
  Download, 
  ShoppingCart,
  Pen,
  Heart,
  Calendar,
  GraduationCap
} from 'lucide-react';

const categories = [
  { id: 'merch', icon: Shirt, label: 'Merch' },
  { id: 'nfts', icon: Blocks, label: 'NFTs' },
  { id: 'subscriptions', icon: CreditCard, label: 'Subscriptions' },
  { id: 'tickets', icon: Ticket, label: 'Tickets' },
  { id: 'auctions', icon: Gavel, label: 'Live Auctions' },
  { id: 'content', icon: Play, label: 'Exclusive Content' },
  { id: 'digital', icon: Download, label: 'Digital Goods' },
  { id: 'physical', icon: ShoppingCart, label: 'Physical Products' },
  { id: 'autographed', icon: Pen, label: 'Autographed Items' },
  { id: 'charity', icon: Heart, label: 'Charity and Fundraising' },
  { id: 'events', icon: Calendar, label: 'Event Bookings' },
  { id: 'courses', icon: GraduationCap, label: 'Courses and Workshops' }
];

export function SellCategories() {
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);

  const toggleCategory = (categoryId: string) => {
    setSelectedCategories(prev => 
      prev.includes(categoryId)
        ? prev.filter(id => id !== categoryId)
        : [...prev, categoryId]
    );
  };

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
      {categories.map(({ id, icon: Icon, label }) => (
        <button
          key={id}
          onClick={() => toggleCategory(id)}
          className={`flex items-center gap-4 p-4 rounded-xl transition-all ${
            selectedCategories.includes(id)
              ? 'bg-[#F8CA7E] text-black shadow-lg scale-[1.02]'
              : 'bg-white dark:bg-gray-800 text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700 shadow-sm'
          }`}
        >
          <div className={`p-3 rounded-lg ${
            selectedCategories.includes(id)
              ? 'bg-white/20'
              : 'bg-gray-100 dark:bg-gray-700'
          }`}>
            <Icon className="w-6 h-6" />
          </div>
          <span className="flex-1 text-left font-medium">{label}</span>
          <div className={`w-6 h-6 rounded-full border-2 transition-colors ${
            selectedCategories.includes(id)
              ? 'border-black bg-black'
              : 'border-gray-300 dark:border-gray-600'
          }`}>
            {selectedCategories.includes(id) && (
              <svg className="w-full h-full text-[#F8CA7E]" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round">
                <polyline points="20 6 9 17 4 12" />
              </svg>
            )}
          </div>
        </button>
      ))}
    </div>
  );
}