import React, { useState } from 'react';
import { Grid, Users, Film, Music, ShoppingBag } from 'lucide-react';
import { CELEBRITY_IMAGES } from '../../types/celebrity';
import { ProfileHeader } from '../../components/profile/ProfileHeader';

const TAYLOR_SWIFT = {
  name: "Taylor Swift",
  username: "taylorswift",
  imageUrl: CELEBRITY_IMAGES['Taylor Swift'],
  coverImage: "https://ddlvbtbyvlqpiizexmlr.supabase.co/storage/v1/object/sign/product-images/celebrity%20cover%20photos/436315563_984292446395907_3233579659610284551_n.jpg?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJwcm9kdWN0LWltYWdlcy9jZWxlYnJpdHkgY292ZXIgcGhvdG9zLzQzNjMxNTU2M185ODQyOTI0NDYzOTU5MDdfMzIzMzU3OTY1OTYxMDI4NDU1MV9uLmpwZyIsImlhdCI6MTczMzM1MTgxNywiZXhwIjoxNzY0ODg3ODE3fQ.YhoDOi_RcANuSDiUBws3v5V8tHbJTGuI6UkFnL4CUkc",
  coverVideo: "https://ddlvbtbyvlqpiizexmlr.supabase.co/storage/v1/object/sign/product-images/taylor%20swifts%20product%20images/Screen%20Recording%20Taylor%20Swifts%20cover%20video.mp4?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJwcm9kdWN0LWltYWdlcy90YXlsb3Igc3dpZnRzIHByb2R1Y3QgaW1hZ2VzL1NjcmVlbiBSZWNvcmRpbmcgVGF5bG9yIFN3aWZ0cyBjb3ZlciB2aWRlby5tcDQiLCJpYXQiOjE3MzUzMzU4OTksImV4cCI6MTc2Njg3MTg5OX0.6K614VTMPZ_1eSbuI02_Ao5-2VipOfltfcZDsGOv_wg",
  bio: "Singer-songwriter and entrepreneur. Shop exclusive merchandise and tour tickets.",
  location: "New York, NY",
  followers: 279000000,
  following: 0,
  verified: true,
  socialLinks: {
    website: "https://store.taylorswift.com",
    instagram: "https://instagram.com/taylorswift",
    twitter: "https://twitter.com/taylorswift13"
  },
  products: [
    {
      id: "1",
      name: "The Tortured Poets Department Gray Hoodie",
      description: "Limited edition gray hoodie featuring The Tortured Poets Department artwork",
      price: 75.00,
      imageUrl: "https://ddlvbtbyvlqpiizexmlr.supabase.co/storage/v1/object/sign/product-images/taylor%20swift%20new%20product%20images/The%20Tortured%20Poets%20Department%20Gray%20Hoodie.webp?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJwcm9kdWN0LWltYWdlcy90YXlsb3Igc3dpZnQgbmV3IHByb2R1Y3QgaW1hZ2VzL1RoZSBUb3J0dXJlZCBQb2V0cyBEZXBhcnRtZW50IEdyYXkgSG9vZGllLndlYnAiLCJpYXQiOjE3MzY5ODAwNDEsImV4cCI6MTc2ODUxNjA0MX0.flAeNrmLxn2MbTTylbyYL7ZCKQTYKgM49EUzAoGh3-0",
      url: "https://store.taylorswift.com"
    },
    {
      id: "2",
      name: "The Tortured Poets Department The Anthology T-Shirt",
      description: "Classic black t-shirt with The Tortured Poets Department Anthology design",
      price: 40.00,
      imageUrl: "https://ddlvbtbyvlqpiizexmlr.supabase.co/storage/v1/object/sign/product-images/taylor%20swift%20new%20product%20images/The%20Tortured%20Poets%20Department%20The%20Anthology%20T-Shirt.webp?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJwcm9kdWN0LWltYWdlcy90YXlsb3Igc3dpZnQgbmV3IHByb2R1Y3QgaW1hZ2VzL1RoZSBUb3J0dXJlZCBQb2V0cyBEZXBhcnRtZW50IFRoZSBBbnRob2xvZ3kgVC1TaGlydC53ZWJwIiwiaWF0IjoxNzM2OTgwMDUxLCJleHAiOjE3Njg1MTYwNTF9.rqV05pKUgwNVsFMgdQ-lPy2PRYIEePzQ0sbYfi6xT7E",
      url: "https://store.taylorswift.com"
    },
    {
      id: "3",
      name: "TTPD Black Embossed Hoodie",
      description: "Premium black hoodie with embossed TTPD design",
      price: 75.00,
      imageUrl: "https://ddlvbtbyvlqpiizexmlr.supabase.co/storage/v1/object/sign/product-images/taylor%20swift%20new%20product%20images/TTPD%20Black%20Embossed%20Hoodie.webp?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJwcm9kdWN0LWltYWdlcy90YXlsb3Igc3dpZnQgbmV3IHByb2R1Y3QgaW1hZ2VzL1RUUEQgQmxhY2sgRW1ib3NzZWQgSG9vZGllLndlYnAiLCJpYXQiOjE3MzY5ODAwNjUsImV4cCI6MTc2ODUxNjA2NX0.2UNPwW_t89Bs3zvhWZoJOo0g4JqL367KRANjjnteuAA",
      url: "https://store.taylorswift.com"
    },
    {
      id: "4",
      name: "TTPD Crewneck Sweater",
      description: "Comfortable crewneck sweater with TTPD logo",
      price: 65.00,
      imageUrl: "https://ddlvbtbyvlqpiizexmlr.supabase.co/storage/v1/object/sign/product-images/taylor%20swift%20new%20product%20images/TTPD%20Crewneck%20Sweater.webp?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJwcm9kdWN0LWltYWdlcy90YXlsb3Igc3dpZnQgbmV3IHByb2R1Y3QgaW1hZ2VzL1RUUEQgQ3Jld25lY2sgU3dlYXRlci53ZWJwIiwiaWF0IjoxNzM2OTgxMjkyLCJleHAiOjE3Njg1MTcyOTJ9.RIV-AxKJUo-TytQiYA811O3OM4ZKX0YMZRiIoQMkbks",
      url: "https://store.taylorswift.com"
    },
    {
      id: "5",
      name: "TTPD Gray Embossed Hoodie",
      description: "Premium gray hoodie with embossed TTPD design",
      price: 75.00,
      imageUrl: "https://ddlvbtbyvlqpiizexmlr.supabase.co/storage/v1/object/sign/product-images/taylor%20swift%20new%20product%20images/TTPD%20Gray%20Embossed%20Hoodie.webp?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJwcm9kdWN0LWltYWdlcy90YXlsb3Igc3dpZnQgbmV3IHByb2R1Y3QgaW1hZ2VzL1RUUEQgR3JheSBFbWJvc3NlZCBIb29kaWUud2VicCIsImlhdCI6MTczNjk4MTI5OSwiZXhwIjoxNzY4NTE3Mjk5fQ.8Cg_XhjGDg4e6xjva9TsJn0eD-57PwV2dsEyEi0i6I0",
      url: "https://store.taylorswift.com"
    },
    {
      id: "6",
      name: "TTPD Black Embossed Sweatpants",
      description: "Comfortable black sweatpants with embossed TTPD design",
      price: 65.00,
      imageUrl: "https://ddlvbtbyvlqpiizexmlr.supabase.co/storage/v1/object/sign/product-images/taylor%20swift%20new%20product%20images/TTPD%20Black%20Embossed%20Sweatpants.webp?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJwcm9kdWN0LWltYWdlcy90YXlsb3Igc3dpZnQgbmV3IHByb2R1Y3QgaW1hZ2VzL1RUUEQgQmxhY2sgRW1ib3NzZWQgU3dlYXRwYW50cy53ZWJwIiwiaWF0IjoxNzM2OTgxMzA3LCJleHAiOjE3Njg1MTczMDd9.1KBSJM1g25UvljMyh7y1MeDBEma6sp_DhQiGnAY3dkY",
      url: "https://store.taylorswift.com"
    }
  ],
  songs: [
    { title: "You Belong With Me", album: "Fearless", duration: "3:51" },
    { title: "Shake It Off", album: "1989", duration: "3:39" },
    { title: "Blank Space", album: "1989", duration: "3:51" },
    { title: "Wildest Dreams", album: "1989", duration: "3:40" },
    { title: "Style", album: "1989", duration: "3:51" },
    { title: "Love Story", album: "Fearless", duration: "3:55" },
    { title: "22", album: "Red", duration: "3:50" },
    { title: "Our Song", album: "Taylor Swift", duration: "3:21" }
  ],
  musicMerch: [
    {
      category: "Hoodies",
      price: 65.00,
      imageUrl: "https://ddlvbtbyvlqpiizexmlr.supabase.co/storage/v1/object/sign/scroll%20and%20shop/Music%20Merch/hoodie.webp?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJzY3JvbGwgYW5kIHNob3AvTXVzaWMgTWVyY2gvaG9vZGllLndlYnAiLCJpYXQiOjE3Mzc0MjQxMjYsImV4cCI6MTc2ODk2MDEyNn0.vVZAlD8cpG-KOxPAOQZ1gpvbv49zYrDU4n1CxnW3efI",
      customizationVideos: {
        "You Belong With Me": "https://ddlvbtbyvlqpiizexmlr.supabase.co/storage/v1/object/sign/scroll%20and%20shop/Music%20Merch/you%20belong%20to%20me.mov?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJzY3JvbGwgYW5kIHNob3AvTXVzaWMgTWVyY2gveW91IGJlbG9uZyB0byBtZS5tb3YiLCJpYXQiOjE3Mzc0MjU0OTMsImV4cCI6MTc2ODk2MTQ5M30.-Zs8dz5PHoCfTztPI_SFP8jRu7DjcSQ8v57ltwlo6dQ",
        "Shake It Off": "https://ddlvbtbyvlqpiizexmlr.supabase.co/storage/v1/object/sign/scroll%20and%20shop/Music%20Merch/Shake%20It%20off.mov?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJzY3JvbGwgYW5kIHNob3AvTXVzaWMgTWVyY2gvU2hha2UgSXQgb2ZmLm1vdiIsImlhdCI6MTczNzQyNTUyMCwiZXhwIjoxNzY4OTYxNTIwfQ.C_hg7wsyoLlgHSHKEaNHxENmMMHqP51ZQzJW4udqjGE"
      }
    },
    {
      category: "T-Shirts",
      price: 35.00,
      imageUrl: "https://ddlvbtbyvlqpiizexmlr.supabase.co/storage/v1/object/sign/scroll%20and%20shop/Music%20Merch/Tshirt.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJzY3JvbGwgYW5kIHNob3AvTXVzaWMgTWVyY2gvVHNoaXJ0LnBuZyIsImlhdCI6MTczNzQyNDE0NywiZXhwIjoxNzY4OTYwMTQ3fQ.rHJ71YClA6jpmnsIp7MkrcYwDIYn5QY37jyUrpjlP8U"
    },
    {
      category: "Hats",
      price: 25.00,
      imageUrl: "https://ddlvbtbyvlqpiizexmlr.supabase.co/storage/v1/object/sign/scroll%20and%20shop/Music%20Merch/hat.webp?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJzY3JvbGwgYW5kIHNob3AvTXVzaWMgTWVyY2gvaGF0LndlYnAiLCJpYXQiOjE3Mzc0MjQxNjAsImV4cCI6MTc2ODk2MDE2MH0.WWeQ7p8KHHZzyqZ3U_vOxsh_b1HvkF4LDWf0n2-Vw0w"
    },
    {
      category: "Phone Cases",
      price: 29.99,
      imageUrl: "https://ddlvbtbyvlqpiizexmlr.supabase.co/storage/v1/object/sign/scroll%20and%20shop/Music%20Merch/i%20phone%20case.webp?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJzY3JvbGwgYW5kIHNob3AvTXVzaWMgTWVyY2gvaSBwaG9uZSBjYXNlLndlYnAiLCJpYXQiOjE3Mzc0MjQxNzYsImV4cCI6MTc2ODk2MDE3Nn0._OW-IWKy70zFQpe-ULyerqQctCr0GQq21oJFGxwV-jQ"
    },
    {
      category: "Canvas Prints",
      price: 49.99,
      imageUrl: "https://ddlvbtbyvlqpiizexmlr.supabase.co/storage/v1/object/sign/scroll%20and%20shop/Music%20Merch/canvas.webp?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJzY3JvbGwgYW5kIHNob3AvTXVzaWMgTWVyY2gvY2FudmFzLndlYnAiLCJpYXQiOjE3Mzc0MjQxODcsImV4cCI6MTc2ODk2MDE4N30.wpajst92GMjkrSgBYoK_nTQSb4AvfQae7EImx0rDC_Y"
    }
  ]
};

export function TaylorSwiftProfile() {
  const [activeTab, setActiveTab] = useState('products');
  const [selectedSong, setSelectedSong] = useState<string | null>(null);
  const [showCustomizationVideo, setShowCustomizationVideo] = useState(false);
  const [currentVideo, setCurrentVideo] = useState<string | null>(null);

  const handleMerchClick = (category: string) => {
    if (category === "Hoodies" && selectedSong) {
      const videos = TAYLOR_SWIFT.musicMerch[0].customizationVideos;
      const videoUrl = videos[selectedSong as keyof typeof videos];
      if (videoUrl) {
        setCurrentVideo(videoUrl);
        setShowCustomizationVideo(true);
      }
    }
  };

  const CustomizationVideoModal = () => {
    if (!showCustomizationVideo || !currentVideo) return null;

    return (
      <div className="fixed inset-0 bg-black/80 flex items-center justify-center z-50 p-4">
        <div className="relative w-full max-w-4xl">
          <button
            onClick={() => setShowCustomizationVideo(false)}
            className="absolute -top-12 right-0 text-white hover:text-[#F8CA7E] transition-colors"
          >
            <span className="text-lg">Close</span>
          </button>
          <div className="relative pt-[56.25%] bg-black rounded-lg overflow-hidden">
            <video
              className="absolute inset-0 w-full h-full object-contain"
              controls
              autoPlay
              src={currentVideo}
            >
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </div>
    );
  };

  const renderMusicMerch = () => {
    if (selectedSong) {
      return (
        <div className="space-y-6">
          <div className="flex items-center justify-between mb-6">
            <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
              Merch for "{selectedSong}"
            </h3>
            <button
              onClick={() => setSelectedSong(null)}
              className="text-sm text-[#F8CA7E] hover:text-[#e5b86b]"
            >
              Back to Songs
            </button>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            {TAYLOR_SWIFT.musicMerch.map((item, index) => (
              <div 
                key={index} 
                className="bg-white dark:bg-gray-800 rounded-lg shadow-sm hover:shadow-md transition-shadow overflow-hidden cursor-pointer"
                onClick={() => handleMerchClick(item.category)}
              >
                <div className="aspect-square relative overflow-hidden group">
                  <img
                    src={item.imageUrl}
                    alt={`${selectedSong} ${item.category}`}
                    className="w-full h-full object-cover object-center group-hover:scale-105 transition-transform duration-300"
                  />
                  {item.category === "Hoodies" && item.customizationVideos?.[selectedSong as keyof typeof item.customizationVideos] && (
                    <div className="absolute inset-0 bg-black/50 opacity-0 group-hover:opacity-100 transition-opacity flex items-center justify-center">
                      <span className="text-white font-medium">Click to preview customization</span>
                    </div>
                  )}
                </div>
                <div className="p-4">
                  <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-1">
                    {selectedSong} {item.category}
                  </h3>
                  <div className="flex items-center justify-between mt-2">
                    <span className="text-xl font-bold text-gray-900 dark:text-white">
                      ${item.price.toFixed(2)}
                    </span>
                    <button 
                      onClick={(e) => {
                        e.stopPropagation();
                        // Add to cart logic here
                      }}
                      className="flex items-center gap-2 px-4 py-2 bg-[#F8CA7E] text-white rounded-lg hover:bg-[#e5b86b] transition-colors"
                    >
                      <ShoppingBag className="w-4 h-4" />
                      <span>Add to Cart</span>
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      );
    }

    return (
      <div className="bg-white dark:bg-gray-800 rounded-lg p-6">
        <div className="space-y-4">
          {TAYLOR_SWIFT.songs.map((song, index) => (
            <div 
              key={index}
              onClick={() => setSelectedSong(song.title)}
              className="flex items-center justify-between p-3 hover:bg-gray-50 dark:hover:bg-gray-700 rounded-lg transition-colors group cursor-pointer"
            >
              <div className="flex items-center gap-4">
                <span className="text-gray-400 group-hover:text-[#F8CA7E] w-6">
                  {index + 1}
                </span>
                <div>
                  <h3 className="font-medium text-gray-900 dark:text-white">
                    {song.title}
                  </h3>
                  <p className="text-sm text-gray-500 dark:text-gray-400">
                    {song.album}
                  </p>
                </div>
              </div>
              <div className="flex items-center gap-4">
                <span className="text-sm text-gray-500 dark:text-gray-400">
                  {song.duration}
                </span>
                <button className="opacity-0 group-hover:opacity-100 p-2 hover:bg-[#F8CA7E]/10 rounded-full transition-all">
                  <Music className="w-5 h-5 text-[#F8CA7E]" />
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderContent = () => {
    switch (activeTab) {
      case 'music':
        return renderMusicMerch();
      case 'products':
        return (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            {TAYLOR_SWIFT.products?.map((product) => (
              <div key={product.id} className="bg-white dark:bg-gray-800 rounded-lg shadow-sm hover:shadow-md transition-shadow overflow-hidden">
                <div className="aspect-square relative overflow-hidden">
                  <img
                    src={product.imageUrl}
                    alt={product.name}
                    className="w-full h-full object-cover object-center group-hover:scale-105 transition-transform duration-300"
                  />
                </div>
                <div className="p-4">
                  <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-1">
                    {product.name}
                  </h3>
                  <p className="text-sm text-gray-600 dark:text-gray-400 mb-3">
                    {product.description}
                  </p>
                  <div className="flex items-center justify-between">
                    <span className="text-xl font-bold text-gray-900 dark:text-white">
                      ${product.price.toFixed(2)}
                    </span>
                    <a
                      href={product.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="px-4 py-2 bg-[#F8CA7E] text-white rounded-lg hover:bg-[#e5b86b] transition-colors text-sm font-medium"
                    >
                      Shop Now
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        );
      default:
        return (
          <div className="flex items-center justify-center h-64 text-gray-500">
            Coming soon
          </div>
        );
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900">
      <div className="max-w-7xl mx-auto">
        <div className="bg-white dark:bg-gray-800 rounded-xl shadow-lg overflow-hidden my-8 mx-4">
          <ProfileHeader celebrity={TAYLOR_SWIFT} />
          
          <div className="p-8">
            <div className="flex justify-center border-b border-gray-200 dark:border-gray-700 mb-8">
              <button 
                onClick={() => setActiveTab('products')}
                className={`px-8 py-4 flex items-center gap-2 transition-colors ${
                  activeTab === 'products' 
                    ? 'border-b-2 border-[#F8CA7E] text-[#F8CA7E]' 
                    : 'text-gray-500 dark:text-gray-400'
                }`}
              >
                <Grid className="w-5 h-5" />
                <span>Products</span>
              </button>
              <button 
                onClick={() => {
                  setActiveTab('music');
                  setSelectedSong(null);
                }}
                className={`px-8 py-4 flex items-center gap-2 transition-colors ${
                  activeTab === 'music' 
                    ? 'border-b-2 border-[#F8CA7E] text-[#F8CA7E]' 
                    : 'text-gray-500 dark:text-gray-400'
                }`}
              >
                <Music className="w-5 h-5" />
                <span>Music Merch</span>
              </button>
              <button 
                onClick={() => setActiveTab('community')}
                className={`px-8 py-4 flex items-center gap-2 transition-colors ${
                  activeTab === 'community' 
                    ? 'border-b-2 border-[#F8CA7E] text-[#F8CA7E]' 
                    : 'text-gray-500 dark:text-gray-400'
                }`}
              >
                <Users className="w-5 h-5" />
                <span>Community</span>
              </button>
              <button 
                onClick={() => setActiveTab('media')}
                className={`px-8 py-4 flex items-center gap-2 transition-colors ${
                  activeTab === 'media' 
                    ? 'border-b-2 border-[#F8CA7E] text-[#F8CA7E]' 
                    : 'text-gray-500 dark:text-gray-400'
                }`}
              >
                <Film className="w-5 h-5" />
                <span>Media</span>
              </button>
            </div>

            {renderContent()}
          </div>
        </div>
      </div>
      <CustomizationVideoModal />
    </div>
  );
}