export const memeVideos = [
  {
    id: 'meme1',
    videoUrl: 'https://ddlvbtbyvlqpiizexmlr.supabase.co/storage/v1/object/sign/scroll%20and%20shop/meme%20videos/Hawk%20Tuah%20Clip%20-%20Original%20(EDIT)%20(%20Hawk%20Tuhh%20).mp4?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJzY3JvbGwgYW5kIHNob3AvbWVtZSB2aWRlb3MvSGF3ayBUdWFoIENsaXAgLSBPcmlnaW5hbCAoRURJVCkgKCBIYXdrIFR1aGggKS5tcDQiLCJpYXQiOjE3MzY4MTk3OTgsImV4cCI6MTc2ODM1NTc5OH0.fbaTLJLQyZ9jUGqtRxvhFHYzZLof-ohQ6jMGktd54MU',
    title: 'Hawk Tuah Limited Edition T-Shirt',
    price: 25.00,
    productImage: 'https://ddlvbtbyvlqpiizexmlr.supabase.co/storage/v1/object/sign/scroll%20and%20shop/meme%20videos/meme%20product%20images/Hawk%20tuah%20product%20image.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJzY3JvbGwgYW5kIHNob3AvbWVtZSB2aWRlb3MvbWVtZSBwcm9kdWN0IGltYWdlcy9IYXdrIHR1YWggcHJvZHVjdCBpbWFnZS5wbmciLCJpYXQiOjE3MzY4OTAyNzQsImV4cCI6MTc2ODQyNjI3NH0.kMmuHYKU2L6Hj9CqggK9W5CiakpKJqO1ArOyxAs8yLI'
  },
  {
    id: 'meme2',
    videoUrl: 'https://ddlvbtbyvlqpiizexmlr.supabase.co/storage/v1/object/sign/scroll%20and%20shop/meme%20videos/Homer%20disappears%20into%20bushes.mp4?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJzY3JvbGwgYW5kIHNob3AvbWVtZSB2aWRlb3MvSG9tZXIgZGlzYXBwZWFycyBpbnRvIGJ1c2hlcy5tcDQiLCJpYXQiOjE3MzY4MTk4MzIsImV4cCI6MTc2ODM1NTgzMn0.vRVQ-SbVDbKd8ztOuen_Q3BWJ4aKER9esxOU_1e4Jwo',
    title: 'Homer Bush Meme T-Shirt',
    price: 25.00,
    productImage: 'https://ddlvbtbyvlqpiizexmlr.supabase.co/storage/v1/object/sign/scroll%20and%20shop/meme%20videos/meme%20product%20images/Homer%20Simpson%20product%20image.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJzY3JvbGwgYW5kIHNob3AvbWVtZSB2aWRlb3MvbWVtZSBwcm9kdWN0IGltYWdlcy9Ib21lciBTaW1wc29uIHByb2R1Y3QgaW1hZ2UucG5nIiwiaWF0IjoxNzM2ODkwMjg3LCJleHAiOjE3Njg0MjYyODd9.WyX01ks40jcepBaT7RBvvi_CJESTYrEYT4Md4WJOl-M'
  },
  {
    id: 'meme3',
    videoUrl: 'https://ddlvbtbyvlqpiizexmlr.supabase.co/storage/v1/object/sign/scroll%20and%20shop/meme%20videos/Spectacular%20Give%20Me%2014.mp4?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJzY3JvbGwgYW5kIHNob3AvbWVtZSB2aWRlb3MvU3BlY3RhY3VsYXIgR2l2ZSBNZSAxNC5tcDQiLCJpYXQiOjE3MzY4MTk4NTMsImV4cCI6MTc2ODM1NTg1M30.BESFwXu1ZIm5NVn9i1cpJv2pbkKc-8dbS5dDK-lZXTo',
    title: 'Spectacular Give Me 14 T-Shirt',
    price: 25.00,
    productImage: 'https://ddlvbtbyvlqpiizexmlr.supabase.co/storage/v1/object/sign/scroll%20and%20shop/meme%20videos/meme%20product%20images/Spectacular%20Give%20me%2014%20of%20them%20product%20Image.jpeg?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJzY3JvbGwgYW5kIHNob3AvbWVtZSB2aWRlb3MvbWVtZSBwcm9kdWN0IGltYWdlcy9TcGVjdGFjdWxhciBHaXZlIG1lIDE0IG9mIHRoZW0gcHJvZHVjdCBJbWFnZS5qcGVnIiwiaWF0IjoxNzM2ODkwMjk3LCJleHAiOjE3Njg0MjYyOTd9.JxqbxzCstUxjjy2m6kwShXAzDC2t78bs2D1SrOOU1O8'
  }
];