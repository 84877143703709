import React from 'react';
import { DollarSign } from 'lucide-react';

interface SalesOverviewProps {
  amount: number;
  change: number;
  period: string;
}

export function SalesOverview({ amount, change, period }: SalesOverviewProps) {
  return (
    <div className="bg-gray-900 rounded-xl p-6">
      <div className="flex items-center gap-3 mb-4">
        <DollarSign className="w-6 h-6 text-[#F8CA7E]" />
        <h3 className="text-xl font-semibold text-white">Sales</h3>
      </div>
      <div className="mb-4">
        <div className="text-4xl font-bold text-white">
          ${(amount / 1000).toFixed(1)}k
        </div>
        <div className="flex items-center gap-2 mt-2">
          <span className={`text-${change >= 0 ? 'green' : 'red'}-400`}>
            {change}%
          </span>
          <span className="text-gray-400">{period}</span>
        </div>
      </div>
    </div>
  );
}