import React from 'react';
import { useParams } from 'react-router-dom';
import { TaylorSwiftProfile } from './celebrities/TaylorSwiftProfile';
import { RihannaProfile } from './celebrities/RihannaProfile';
import { CristianoRonaldoProfile } from './celebrities/CristianoRonaldoProfile';

export function CelebrityProfile() {
  const { username } = useParams();
  const normalizedUsername = username?.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/\s+/g, '');

  // Return specific celebrity profile based on username
  switch (normalizedUsername) {
    case 'taylorswift':
      return <TaylorSwiftProfile />;
    case 'rihanna':
      return <RihannaProfile />;
    case 'cristianoronaldo':
      return <CristianoRonaldoProfile />;
    default:
      return (
        <div className="min-h-screen bg-white dark:bg-gray-900 flex items-center justify-center">
          <div className="text-center">
            <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-2">
              Celebrity not found
            </h2>
            <p className="text-gray-600 dark:text-gray-400">
              The celebrity you're looking for doesn't exist in our database.
            </p>
          </div>
        </div>
      );
  }
}