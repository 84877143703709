import React, { useEffect, useRef, useState } from 'react';

interface CoverVideoProps {
  videoUrl?: string;
  posterUrl: string;
  className?: string;
}

export function CoverVideo({ videoUrl, posterUrl, className = '' }: CoverVideoProps) {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    const videoElement = videoRef.current;
    if (!videoElement || !videoUrl) return;

    const handlePlay = async () => {
      try {
        // Only attempt to play if the video is paused
        if (videoElement.paused) {
          await videoElement.play();
        }
      } catch (error) {
        console.error('Video playback error:', error);
        setHasError(true);
      }
    };

    // Reset error state when video URL changes
    setHasError(false);

    // Load and play video
    videoElement.load();
    
    // Wait for metadata to load before attempting to play
    videoElement.addEventListener('loadedmetadata', handlePlay);
    
    return () => {
      videoElement.removeEventListener('loadedmetadata', handlePlay);
      if (!videoElement.paused) {
        videoElement.pause();
      }
    };
  }, [videoUrl]);

  if (!videoUrl || hasError) {
    return (
      <img
        src={posterUrl}
        alt="Cover"
        className={`w-full h-full object-cover ${className}`}
      />
    );
  }

  return (
    <video
      ref={videoRef}
      className={`w-full h-full object-cover ${className}`}
      poster={posterUrl}
      loop
      muted
      playsInline
      onError={() => setHasError(true)}
    >
      <source src={videoUrl} type="video/mp4" />
      <img
        src={posterUrl}
        alt="Cover"
        className="w-full h-full object-cover"
      />
    </video>
  );
}