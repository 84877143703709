import React from 'react';
import { SellCategories } from '../../components/sell/SellCategories';

export function SellPage() {
  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900 p-6">
      <div className="max-w-7xl mx-auto">
        <h1 className="text-3xl font-bold mb-8 text-[#F8CA7E] text-center">
          What Do You Want to Sell Ben?
        </h1>
        <SellCategories />
      </div>
    </div>
  );
}