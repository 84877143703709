import React from 'react';
import { useNavigate } from 'react-router-dom';

interface LogoProps {
  size?: 'small' | 'medium' | 'large' | 'xl';
  className?: string;
}

export function Logo({ size = 'medium', className = '' }: LogoProps) {
  const navigate = useNavigate();
  const sizes = {
    small: 'text-2xl',
    medium: 'text-4xl',
    large: 'text-5xl',
    xl: 'text-6xl'
  };

  return (
    <div 
      className={`flex items-center cursor-pointer ${className}`}
      onClick={() => navigate('/dashboard')}
    >
      <div className="relative">
        <h1 className={`font-['Damion'] ${sizes[size]} text-gray-900 dark:text-white`}>
          Stardom
        </h1>
        <span className="absolute -right-2 top-0 text-xs font-medium text-gray-500 dark:text-gray-400">
          Demo
        </span>
      </div>
    </div>
  );
}