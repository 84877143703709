import React, { useState } from 'react';
import { CheckCircle2, MessageCircle, Mail } from 'lucide-react';
import { Celebrity } from '../../types/celebrity';
import { CoverVideo } from '../media/CoverVideo';

interface ProfileHeaderProps {
  celebrity: Celebrity;
}

export function ProfileHeader({ celebrity }: ProfileHeaderProps) {
  const [showContactOptions, setShowContactOptions] = useState(false);

  return (
    <div>
      {/* Cover Media */}
      <div className="h-[360px] md:h-[400px] w-full overflow-hidden">
        <CoverVideo
          videoUrl={celebrity.coverVideo}
          posterUrl={celebrity.coverImage}
        />
      </div>

      {/* Profile Info Section */}
      <div className="bg-white dark:bg-gray-800 border-b border-gray-200 dark:border-gray-700">
        <div className="container mx-auto px-4 py-6">
          <div className="max-w-[820px] mx-auto">
            <div className="flex items-center gap-8">
              {/* Profile Picture */}
              <div className="w-32 h-32 rounded-full overflow-hidden shadow-lg">
                <img
                  src={celebrity.imageUrl}
                  alt={celebrity.name}
                  className="w-full h-full object-cover"
                />
              </div>

              {/* Name and Actions */}
              <div className="flex-1">
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-3">
                    <h1 className="text-3xl font-bold text-gray-900 dark:text-white">
                      {celebrity.name}
                    </h1>
                    {celebrity.verified && (
                      <CheckCircle2 className="w-6 h-6 text-[#F8CA7E]" />
                    )}
                  </div>
                  <div className="flex items-center gap-3">
                    <button className="px-6 py-2 bg-[#F8CA7E] text-white rounded-full hover:bg-[#e5b86b] transition-colors">
                      Follow
                    </button>
                    <div className="relative">
                      <button 
                        onClick={() => setShowContactOptions(!showContactOptions)}
                        className="p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full transition-colors"
                      >
                        <MessageCircle className="w-5 h-5 text-gray-600 dark:text-gray-400" />
                      </button>
                      {showContactOptions && (
                        <div className="absolute right-0 mt-2 w-48 bg-white dark:bg-gray-800 rounded-lg shadow-lg py-2 z-50">
                          <button className="w-full px-4 py-2 text-left hover:bg-gray-100 dark:hover:bg-gray-700 flex items-center gap-2">
                            <MessageCircle className="w-4 h-4" />
                            <span>Send DM</span>
                          </button>
                          <button className="w-full px-4 py-2 text-left hover:bg-gray-100 dark:hover:bg-gray-700 flex items-center gap-2">
                            <Mail className="w-4 h-4" />
                            <span>Request Booking</span>
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <p className="text-gray-600 dark:text-gray-400 mt-2">@{celebrity.username}</p>
                
                {/* Bio */}
                <p className="text-gray-700 dark:text-gray-300 mt-4">
                  {celebrity.bio}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}