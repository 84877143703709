import React, { createContext, useContext, useState, useEffect } from 'react';
import { AuthError } from '@supabase/supabase-js';
import { AuthService } from '../services/AuthService';

interface User {
  id: string;
  email: string;
}

interface AuthContextType {
  user: User | null;
  loading: boolean;
  signIn: (email: string, password: string) => Promise<{ error: AuthError | null }>;
  signUp: (email: string, password: string) => Promise<void>;
  signOut: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const [authService] = useState(() => AuthService.getInstance());

  useEffect(() => {
    const initAuth = async () => {
      try {
        const { data: { session } } = await authService.getSession();
        
        if (session?.user) {
          setUser({
            id: session.user.id,
            email: session.user.email || ''
          });
        }
      } catch (err) {
        console.error('Auth initialization error:', err);
        setError(err as Error);
      } finally {
        setLoading(false);
      }
    };

    initAuth();
  }, [authService]);

  async function signIn(email: string, password: string) {
    const { error } = await authService.signInWithPassword({ email, password });
    if (!error) {
      const { data: { session } } = await authService.getSession();
      if (session?.user) {
        setUser({
          id: session.user.id,
          email: session.user.email || ''
        });
      }
    }
    return { error };
  }

  async function signUp(email: string, password: string) {
    // Implement sign up logic
  }

  async function signOut() {
    await authService.signOut();
    setUser(null);
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center p-4">
        <div className="bg-red-50 border border-red-200 text-red-600 px-4 py-3 rounded-md">
          <p className="font-medium">Authentication Error</p>
          <p className="text-sm mt-1">Please try signing in again.</p>
        </div>
      </div>
    );
  }

  return (
    <AuthContext.Provider value={{ user, loading, signIn, signUp, signOut }}>
      {children}
    </AuthContext.Provider>
  );
}

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};