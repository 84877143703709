import React from 'react';
import { Grid, Users, Film } from 'lucide-react';
import { CELEBRITY_IMAGES } from '../../types/celebrity';
import { ProfileHeader } from '../../components/profile/ProfileHeader';

const CRISTIANO_RONALDO = {
  name: "Cristiano Ronaldo",
  username: "cristiano",
  imageUrl: CELEBRITY_IMAGES['Cristiano Ronaldo'],
  coverImage: "https://ddlvbtbyvlqpiizexmlr.supabase.co/storage/v1/object/sign/product-images/christiano%20Ronaldo/Screenshot%202024-12-05%20205206.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJwcm9kdWN0LWltYWdlcy9jaHJpc3RpYW5vIFJvbmFsZG8vU2NyZWVuc2hvdCAyMDI0LTEyLTA1IDIwNTIwNi5wbmciLCJpYXQiOjE3MzY5OTIxNTEsImV4cCI6MTc2ODUyODE1MX0.kApJVCD8l8dMxXRI1kS4nrD-9dZF4CUFVZgDuuNLWtA",
  bio: "Professional footballer and entrepreneur. Shop exclusive CR7 products and merchandise.",
  location: "Riyadh, Saudi Arabia",
  followers: 615000000,
  following: 563,
  verified: true,
  socialLinks: {
    website: "https://www.cristianoronaldo.com/shop",
    instagram: "https://instagram.com/cristiano",
    twitter: "https://twitter.com/cristiano"
  },
  products: [
    {
      id: "1",
      name: "CR7 Loungewear Set",
      description: "Comfortable loungewear set from the CR7 collection",
      price: 89.99,
      imageUrl: "https://ddlvbtbyvlqpiizexmlr.supabase.co/storage/v1/object/sign/product-images/christiano%20Ronaldo/CR7%20Product%20images/Loungewear.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJwcm9kdWN0LWltYWdlcy9jaHJpc3RpYW5vIFJvbmFsZG8vQ1I3IFByb2R1Y3QgaW1hZ2VzL0xvdW5nZXdlYXIucG5nIiwiaWF0IjoxNzM2OTkxMDYzLCJleHAiOjE3Njg1MjcwNjN9.-gnRQ5NJObNsWJ2zHuGa2uX5hTvHJEaYzOhkTFY3adw",
      url: "https://www.cristianoronaldo.com/shop"
    },
    {
      id: "2",
      name: "CR7 Men's Bathrobe",
      description: "Luxurious bathrobe with CR7 branding",
      price: 129.99,
      imageUrl: "https://ddlvbtbyvlqpiizexmlr.supabase.co/storage/v1/object/sign/product-images/christiano%20Ronaldo/CR7%20Product%20images/CR7%20Men's%20Bathrobe.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJwcm9kdWN0LWltYWdlcy9jaHJpc3RpYW5vIFJvbmFsZG8vQ1I3IFByb2R1Y3QgaW1hZ2VzL0NSNyBNZW4ncyBCYXRocm9iZS5wbmciLCJpYXQiOjE3MzY5OTEwNzksImV4cCI6MTc2ODUyNzA3OX0.ADTVLoadAIKY0PZSv5z06ClwXNn1a_fKsunxr0s-qG8",
      url: "https://www.cristianoronaldo.com/shop"
    },
    {
      id: "3",
      name: "CR7 Discover Eau de Toilette",
      description: "Signature fragrance by Cristiano Ronaldo, 3.4oz",
      price: 79.99,
      imageUrl: "https://ddlvbtbyvlqpiizexmlr.supabase.co/storage/v1/object/sign/product-images/christiano%20Ronaldo/CR7%20Product%20images/Cristiano%20Ronaldo%20Discover%20Eau%20de%20Toilette%203.4oz.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJwcm9kdWN0LWltYWdlcy9jaHJpc3RpYW5vIFJvbmFsZG8vQ1I3IFByb2R1Y3QgaW1hZ2VzL0NyaXN0aWFubyBSb25hbGRvIERpc2NvdmVyIEVhdSBkZSBUb2lsZXR0ZSAzLjRvei5wbmciLCJpYXQiOjE3MzY5OTExNDQsImV4cCI6MTc2ODUyNzE0NH0.sCvXe5JlIN_aaTg_StqU7qWTgZaJAUwRZQFaQeOd0XY",
      url: "https://www.cristianoronaldo.com/shop"
    },
    {
      id: "4",
      name: "CR7 Travel Bags Set",
      description: "Premium travel bags set with CR7 branding",
      price: 199.99,
      imageUrl: "https://ddlvbtbyvlqpiizexmlr.supabase.co/storage/v1/object/sign/product-images/christiano%20Ronaldo/CR7%20Product%20images/CR&%20Travel%20Bags.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJwcm9kdWN0LWltYWdlcy9jaHJpc3RpYW5vIFJvbmFsZG8vQ1I3IFByb2R1Y3QgaW1hZ2VzL0NSJiBUcmF2ZWwgQmFncy5wbmciLCJpYXQiOjE3MzY5OTExNjAsImV4cCI6MTc2ODUyNzE2MH0.etW3RbK4JpYpB0q8rTpdYYcnxxbdtsIVha5ps6-crdM",
      url: "https://www.cristianoronaldo.com/shop"
    },
    {
      id: "5",
      name: "CR7 Men's 5-Pack Boxer-Briefs",
      description: "Bamboo blend boxer-briefs with CR7 branding, pack of 5",
      price: 49.99,
      imageUrl: "https://ddlvbtbyvlqpiizexmlr.supabase.co/storage/v1/object/sign/product-images/christiano%20Ronaldo/CR7%20Product%20images/CR7%20Men's%205-Pack%20Bamboo%20Blend%20Boxer-Briefs.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJwcm9kdWN0LWltYWdlcy9jaHJpc3RpYW5vIFJvbmFsZG8vQ1I3IFByb2R1Y3QgaW1hZ2VzL0NSNyBNZW4ncyA1LVBhY2sgQmFtYm9vIEJsZW5kIEJveGVyLUJyaWVmcy5wbmciLCJpYXQiOjE3MzY5OTExODUsImV4cCI6MTc2ODUyNzE4NX0.6vq-Qaxy9lh5kWtppKjcPzMylaKjS0bKKJ0Xl94Zt3A",
      url: "https://www.cristianoronaldo.com/shop"
    },
    {
      id: "6",
      name: "CR7 Men's Fashion Socks 3-Pack",
      description: "Cotton blend fashion socks with CR7 branding, multicolor pack of 3",
      price: 24.99,
      imageUrl: "https://ddlvbtbyvlqpiizexmlr.supabase.co/storage/v1/object/sign/product-images/christiano%20Ronaldo/CR7%20Product%20images/CR7%20Men's%20Cotton%20Blend%203-Pack%20Fashion%20Socks,%20multicolor.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJwcm9kdWN0LWltYWdlcy9jaHJpc3RpYW5vIFJvbmFsZG8vQ1I3IFByb2R1Y3QgaW1hZ2VzL0NSNyBNZW4ncyBDb3R0b24gQmxlbmQgMy1QYWNrIEZhc2hpb24gU29ja3MsIG11bHRpY29sb3IucG5nIiwiaWF0IjoxNzM2OTkyMjMwLCJleHAiOjE3Njg1MjgyMzB9.5wfWcNRPgPHRsXWumF2rg6ahIF2Y0CPd-MXcbzRdaHM",
      url: "https://www.cristianoronaldo.com/shop"
    }
  ]
};

export function CristianoRonaldoProfile() {
  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900">
      <div className="max-w-7xl mx-auto">
        <div className="bg-white dark:bg-gray-800 rounded-xl shadow-lg overflow-hidden my-8 mx-4">
          <ProfileHeader celebrity={CRISTIANO_RONALDO} />
          
          <div className="p-8">
            <div className="flex justify-center border-b border-gray-200 dark:border-gray-700 mb-8">
              <button className="px-8 py-4 border-b-2 border-[#F8CA7E] text-[#F8CA7E] flex items-center gap-2">
                <Grid className="w-5 h-5" />
                <span>Products</span>
              </button>
              <button className="px-8 py-4 text-gray-500 dark:text-gray-400 flex items-center gap-2">
                <Users className="w-5 h-5" />
                <span>Community</span>
              </button>
              <button className="px-8 py-4 text-gray-500 dark:text-gray-400 flex items-center gap-2">
                <Film className="w-5 h-5" />
                <span>Media</span>
              </button>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
              {CRISTIANO_RONALDO.products?.map((product) => (
                <div key={product.id} className="bg-white dark:bg-gray-800 rounded-lg shadow-sm hover:shadow-md transition-shadow overflow-hidden">
                  <div className="aspect-square relative overflow-hidden">
                    <img
                      src={product.imageUrl}
                      alt={product.name}
                      className="w-full h-full object-cover object-center group-hover:scale-105 transition-transform duration-300"
                    />
                  </div>
                  
                  <div className="p-4">
                    <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-1">
                      {product.name}
                    </h3>
                    <p className="text-sm text-gray-600 dark:text-gray-400 mb-3">
                      {product.description}
                    </p>
                    <div className="flex items-center justify-between">
                      <span className="text-xl font-bold text-gray-900 dark:text-white">
                        ${product.price.toFixed(2)}
                      </span>
                      <a
                        href={product.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="px-4 py-2 bg-[#F8CA7E] text-white rounded-lg hover:bg-[#e5b86b] transition-colors text-sm font-medium"
                      >
                        Shop Now
                      </a>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}