import React from 'react';
import { TrendingUp } from 'lucide-react';

interface AOVGaugeProps {
  amount: number;
  min: number;
  max: number;
}

export function AOVGauge({ amount, min, max }: AOVGaugeProps) {
  return (
    <div className="bg-gray-900 rounded-xl p-6">
      <div className="flex items-center gap-3 mb-4">
        <TrendingUp className="w-6 h-6 text-[#F8CA7E]" />
        <h3 className="text-xl font-semibold text-white">AOV - this week</h3>
      </div>
      <div className="flex justify-center items-center">
        <div className="relative w-40 h-40">
          <div className="absolute inset-0 flex items-center justify-center">
            <span className="text-2xl font-bold text-white">${amount}</span>
          </div>
          <svg className="transform -rotate-90" viewBox="0 0 100 100">
            <circle
              cx="50"
              cy="50"
              r="45"
              fill="none"
              stroke="#374151"
              strokeWidth="10"
            />
            <circle
              cx="50"
              cy="50"
              r="45"
              fill="none"
              stroke="#F8CA7E"
              strokeWidth="10"
              strokeDasharray={`${((amount - min) / (max - min)) * 283} 283`}
            />
          </svg>
        </div>
      </div>
    </div>
  );
}