import React from 'react';
import { Wallet, ArrowDownLeft, ArrowUpRight, Bitcoin } from 'lucide-react';

const balances = [
  { currency: 'USD', amount: 7699.00, symbol: '$' },
  { currency: 'BTC', amount: 0.547, symbol: '₿', usdPrice: 72000 }
];

export function WalletOverview() {
  return (
    <div className="bg-white dark:bg-gray-800 rounded-xl shadow-sm p-6">
      <div className="flex items-center justify-between mb-6">
        <div className="flex items-center gap-3">
          <div className="p-3 bg-[#F8CA7E]/10 rounded-lg">
            <Wallet className="w-6 h-6 text-[#F8CA7E]" />
          </div>
          <h2 className="text-xl font-semibold text-gray-900 dark:text-white">Your Balance</h2>
        </div>
        <div className="flex gap-2">
          <button className="flex items-center gap-2 px-4 py-2 bg-[#F8CA7E] text-black rounded-lg hover:bg-[#e5b86b] transition-colors">
            <ArrowDownLeft className="w-4 h-4" />
            <span>Add Funds</span>
          </button>
          <button className="flex items-center gap-2 px-4 py-2 bg-gray-100 dark:bg-gray-700 text-gray-700 dark:text-gray-300 rounded-lg hover:bg-gray-200 dark:hover:bg-gray-600 transition-colors">
            <ArrowUpRight className="w-4 h-4" />
            <span>Withdraw</span>
          </button>
        </div>
      </div>

      <div className="grid grid-cols-1 gap-4">
        {/* USD Balance - Primary */}
        <div className="bg-gradient-to-r from-[#F8CA7E]/10 to-[#F8CA7E]/5 dark:from-[#F8CA7E]/20 dark:to-[#F8CA7E]/10 rounded-lg p-6">
          <div className="flex items-center gap-3 mb-2">
            <div className="w-6 h-6 rounded-full bg-[#F8CA7E] flex items-center justify-center text-white font-bold">
              $
            </div>
            <span className="text-sm text-gray-600 dark:text-gray-400">USD Balance</span>
          </div>
          <div className="text-3xl font-bold text-gray-900 dark:text-white">
            ${balances[0].amount.toLocaleString()}
          </div>
        </div>

        {/* Bitcoin Balance - Secondary */}
        <div className="bg-gray-50 dark:bg-gray-700/50 rounded-lg p-6">
          <div className="flex items-center gap-3 mb-2">
            <Bitcoin className="w-5 h-5 text-orange-500" />
            <span className="text-sm text-gray-600 dark:text-gray-400">Bitcoin Balance</span>
          </div>
          <div className="flex items-end gap-3">
            <div className="text-2xl font-semibold text-gray-900 dark:text-white">
              ₿{balances[1].amount.toFixed(8)}
            </div>
            <div className="text-sm text-gray-500 dark:text-gray-400 mb-1">
              ≈ ${(balances[1].amount * balances[1].usdPrice).toLocaleString()}
            </div>
          </div>
        </div>
      </div>

      {/* Bitcoin Price */}
      <div className="mt-6 pt-6 border-t border-gray-200 dark:border-gray-700">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <Bitcoin className="w-5 h-5 text-orange-500" />
            <span className="text-sm text-gray-600 dark:text-gray-400">Bitcoin Price</span>
          </div>
          <div className="text-lg font-semibold text-gray-900 dark:text-white">
            ${balances[1].usdPrice.toLocaleString()}
          </div>
        </div>
      </div>
    </div>
  );
}