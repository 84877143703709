import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Sidebar } from '../components/layout/Sidebar';
import { Header } from '../components/layout/Header';
import { BottomNavigation } from '../components/layout/BottomNavigation';
import { SearchHero } from '../components/search/SearchHero';
import { RecommendedSection } from '../components/home/RecommendedSection';
import { SuggestionsSection } from '../components/suggestions/SuggestionsSection';
import { CategoryBrowse } from './explore/CategoryBrowse';
import { NewsPage } from './news/NewsPage';
import { StarversePage } from './starverse/StarversePage';
import { CommunityPage } from './community/CommunityPage';
import { CelebrityProfile } from './CelebrityProfile';
import { AccountSettings } from './settings/AccountSettings';
import { StardomAIChat } from './ai/StardomAIChat';
import { WalletPage } from './wallet/WalletPage';
import { SellPage } from './sell/SellPage';
import { EventsPage } from './events/EventsPage';

export function Dashboard() {
  return (
    <div className="flex min-h-screen bg-[#FAFAFA] dark:bg-gray-900 transition-colors">
      <div className="hidden lg:block fixed left-0 top-0 h-screen">
        <Sidebar />
      </div>

      <div className="flex-1 lg:ml-64">
        <Header />
        <main className="mt-0 pb-28 lg:pb-0">
          <Routes>
            <Route
              path="/"
              element={
                <div>
                  <div className="flex gap-6 px-4">
                    <div className="flex-1">
                      <SearchHero />
                      <div className="max-w-7xl mx-auto">
                        <RecommendedSection />
                      </div>
                    </div>
                    <div className="hidden xl:block w-[350px] pt-8">
                      <SuggestionsSection />
                    </div>
                  </div>
                </div>
              }
            />
            <Route path="/ai" element={<StardomAIChat />} />
            <Route path="/news" element={<NewsPage />} />
            <Route path="/wallet" element={<WalletPage />} />
            <Route path="/events" element={<EventsPage />} />
            <Route path="/explore" element={<CategoryBrowse />} />
            <Route path="/community" element={<CommunityPage />} />
            <Route path="/starverse" element={<StarversePage />} />
            <Route path="/sell" element={<SellPage />} />
            <Route path="/celebrity/:username" element={<CelebrityProfile />} />
            <Route path="/settings" element={<AccountSettings />} />
          </Routes>
        </main>
        <BottomNavigation />
      </div>
    </div>
  );
}